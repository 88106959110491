import React, { useEffect, useMemo, useState } from "react";
import { Fetcher } from "swr";
import {
  Box,
  Button,
  Checkbox,
  checkboxClasses,
  Chip,
  chipClasses,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  svgIconClasses,
  Tooltip,
  typographyClasses,
  Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import Empty from "@/apps/Modals/components/Empty/Empty";
import { useInfiniteScroll, useStores } from "@/hooks";
import { Search } from "@/components/form/search";
import {
  IPaginatedResult
} from "@/api/interfaces/responses";
import { styled } from "@mui/system";
import { FILTERS_TYPE } from "@/const";
import UncontolledAutoComplete, {
  IProps as IAutocompleteProps,
} from "@/components/Autocompletes/UncontolledAutoComplete";
import theme from "@/theme";

const QUERY_KEY = "GET_ENTITIES";

const chipStyles = {
  [`&.${chipClasses.root} > .${svgIconClasses.root}`]: {
    fontSize: 20,
  },
  [`&.${chipClasses.root} > .${chipClasses.label}`]: {
    fontWeight: 500,
  },
};

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    [`& .${checkboxClasses.root}`]: {
      padding: 0,
    },
    [`& .${typographyClasses.root}`]: {
      fontSize: 14,
      width: "100%",
      padding: theme.spacing(1),
    },
  }),
);

interface IAutocompleteFilter extends IAutocompleteProps {
  type: typeof FILTERS_TYPE.AUTOCOMPLETE;
}

interface ICheckboxFilter {
  type: typeof FILTERS_TYPE.CHECKBOX;
  label: string;
  checked?: boolean;
  name: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

type TFilterType = ICheckboxFilter | IAutocompleteFilter;

interface IAddEntities<T> {
  handleClose: () => void;
  modalProps: {
    title: string;
    subTitle?: string;    
    addButtonLabel?: string;
    saveButtonLabel?: string;
    handleAccept: (payload: T[]) => void;
    disabled?: boolean;
    initialValue?: T[];
    handleAddClose?: () => void;
    handleAdd?: (payload: T[]) => void;
    key?: any;
    fetcher: Fetcher<IPaginatedResult<T>>;
    getLabel: (payload: T) => string;
    getMeta?: (payload: T) => string;
    getDescription?: (payload: T) => string;
    isDisabledBtnAdd?: boolean;
    tooltipText?: string | undefined;
    renderControlLabel?: (payload: T) => React.ReactNode;
    filters?: TFilterType[];
    noWrapFilters?: boolean;
    listTitle?: string;
    q?: Record<string, any>;
    emptyText?: string;
  };
}

/**
 * Модалка для добавления нескольких сущностей заявитель/представитель/автор итп
 * */

const AddEntities = <T extends { id: number }>({
  handleClose,
  modalProps,
}: IAddEntities<T>) => {
  const {
    addButtonLabel,
    saveButtonLabel = "Сохранить",
    disabled = false,
    handleAccept,
    initialValue = [],
    handleAdd,
    fetcher,
    getLabel,
    getMeta,
    getDescription,
    key,
    isDisabledBtnAdd = false,
    tooltipText,
    renderControlLabel,
    filters = [],
    listTitle = "",
    q = {},
    subTitle = "",
    noWrapFilters = false,
    emptyText,
  } = modalProps;
  const { swrStore } = useStores();
  const [value, setValue] = useState<T[]>(initialValue);
  const checked = useMemo(
    () => value.map((el) => el.id),
    [value],
  );
  const [search, setSearch] = useState("");
  const handleSearch = (value: string): void => {
    setSearch(value);
    handleReset();
  };

  const handleSubmit = () => {
    handleAccept(value);
  };
  const getKey = (index: number) => {
    return {
      ...q,
      key: key || QUERY_KEY,
      page: index + 1,
      search,
    };
  };
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const value = JSON.parse(event.target.value);
    if (event.target.checked) {
      setValue((prev) => [...prev, value]);
    } else {
      setValue((prev) => prev.filter((el) => el.id !== id));
    }
  };
  const handleClear = () => {
    setValue([]);
  };
  const handleAddOpenModal = () => {
    handleAdd && handleAdd(value);
  };

  const {
    data,
    handleScroll,
    handleReset,
    isEmpty,
    mutate,
  } = useInfiniteScroll<T>(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  useEffect(() => {
    handleReset();
    if (isEmpty) mutate();
  }, []);


  swrStore.addMutator(key || QUERY_KEY, mutate);

  const renderLabel = (el: T) =>
    renderControlLabel ? (
      renderControlLabel(el)
    ) : (
      <Typography fontSize={14}>{getLabel(el)}</Typography>
    );

  const renderFilters = () =>
    Array.isArray(filters) && filters.length ? (
      <Box p={3} pb={0}>
        <Typography
          variant="body2"
          color={theme.palette.blackAndWhite.gray}
          mb="20px"
        >
          Фильтры
        </Typography>
        <Box flexWrap={ noWrapFilters 
            ? "nowrap" 
            : "wrap" 
          } 
          display="flex" 
          gap="20px"
        >
          {filters.map((filter) => {
            return filter.type === FILTERS_TYPE.CHECKBOX ? (
              <FormControlLabel
                key={filter.name}
                name={filter.name}
                control={<Checkbox defaultChecked={filter.checked} size="small" />}
                label={
                  <Typography 
                    variant="body2"
                    sx={{
                      textWrap: "nowrap",
                    }}
                    >
                    {filter.label}
                  </Typography>
                }
                onChange={filter.handleChange}
              />
            ) : (
              <UncontolledAutoComplete
                key={filter.name}
                {...filter}
                fullWidth
              />
            );
          })}
        </Box>
      </Box>
    ) : null;

  return (
    <Box>
      <Box
        p={3}
        marginRight={6}
        display="flex"
        justifyContent="space-between"
        alignItems= {subTitle ? "flex-start" : "center" }
      >
        <Stack 
          direction="column"
          spacing={1}>
          { subTitle ? 
            <Typography 
              color={theme.palette.blackAndWhite.gray} 
              variant="body1" 
              mb={"-8px"}
              maxWidth={"500px"}
              >
              {modalProps.subTitle}
            </Typography> : null
          }
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="h2" fontSize={24}>
              {modalProps.title}
            </Typography>
            {value.length > 0 && (
              <Chip
                label={value.length}
                size="small"
                variant="outlined"
                sx={chipStyles}
                onDelete={handleClear}
              />
            )}
          </Stack>
        </Stack>
        {addButtonLabel && (
          <Tooltip
            title={!isDisabledBtnAdd ? tooltipText : ""}
            followCursor
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                disabled={!isDisabledBtnAdd}
                onClick={handleAddOpenModal}
              >
                {addButtonLabel}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Divider />
      <Box>
        {renderFilters()}
        {listTitle && (
          <Box pl={3} pr={3} pt={3}>
            <Typography
              variant="body2"
              color={theme.palette.blackAndWhite.gray}
            >
              {listTitle}
            </Typography>
          </Box>
        )}
        <Box p={3}>
          <Search
            onSearch={handleSearch}
            size="small"
            fullWidth
          />
        </Box>
        <Box
          sx={{
            height: 300,
            overflowY: "auto",
          }}
          onScroll={handleScroll}
        >
          <Box p={3} pt={0}>
            {isEmpty ? <Empty emptyText={emptyText} /> : null}
            <FormGroup onChange={handleCheckboxChange}>
              <Stack direction="column" spacing="20px">
                {data.map((el) => {
                  return (
                    <Grid container alignItems="center" key={el.id} sx={{borderBottom: `1px solid ${theme.palette.blackAndWhite.stroke}`}}>
                      <Grid item xs={true}>
                        <StyledFormControlLabel
                          name={String(el.id)}
                          value={JSON.stringify(el)}
                          checked={checked.includes(el.id)}
                          control={
                            <Checkbox color="secondary" />
                          }
                          label={renderLabel(el)}
                        />
                      </Grid>
                      {
                        getDescription && (
                          <Grid item xs={true}>
                            <Typography textAlign="center" variant="body2"
                            >
                              {getDescription(el)}
                            </Typography>
                          </Grid>
                        )
                      }
                      {
                        getMeta && (
                          <Grid item xs={true}>
                            <Typography
                              textAlign="right"
                              sx={{
                                color: theme.palette.blackAndWhite.gray,
                                fontSize: 14
                              }}
                            >
                              {getMeta(el)}
                            </Typography>
                          </Grid>
                        )
                      }
                    </Grid>
                  );
                })}
              </Stack>
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={!value.length || disabled}
          onClick={handleSubmit}
        >
          {saveButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEntities;
