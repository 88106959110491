import React, { useLayoutEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import useSWR from "swr";
import dayjs from "dayjs";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import WorkForm
  from "@/pages/AddCultivarFieldWork/components/WorkForm";
import {
  IFormik,
  initialValues,
  serializeValues,
  validationSchema,
  serializerChemistry
} from "./const";
import { FormikValues } from "formik/dist/types";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  SWR_KEYS,
  LOCATION_KEY,
  KEYS_SIDEBAR_VARIANT_MODULES
} from "@/const";
import Loader from "@/components/Loader";
import {
  QUERY_STRING_MICRO_ROUTS_CULTIVAR_FIELD
} from "@/apps/RightSidebar/const";

const AddCultivarFieldWork = () => {
  const { id, cultivarFieldId } = useParams();
  const location = useLocation();
  const {
    headerStore,
    api,
    toastStore,
    swrStore,
    mapStore,queryStringSidebarCollector
  } = useStores();
  const navigate = useNavigate();
  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const { data, isLoading, mutate, error } = useSWR(
    id ? SWR_KEYS.getCultivarFieldWorkById(id) : null,
    () => api.agriculturalLabour.getCultivarFieldWorkById(id as string),
    {
      onError: showToast
    }
  );

  const { data: renderData } = useSWR(
    cultivarFieldId ? SWR_KEYS.getAgriculturalLabourCultivarField(cultivarFieldId) : null,
    () => api.agriculturalLabour.getCultivarFieldById(cultivarFieldId as string),     
    {
      onError: showToast
    }
  );
  
  const cultivar = renderData?.cultivar?.id
  const plot = renderData?.field?.location?.plot?.id

  const mutateList =
    swrStore.mutators[SWR_KEYS.getAgriculturalLabourLocationList()];

  const handleBack = () => {
    if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate(-1);
    } else {
      const { str } = queryStringSidebarCollector.setup({
        tab: QUERY_STRING_MICRO_ROUTS_CULTIVAR_FIELD.TECHNICAL_MAP.path,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD,
        module_id: cultivarFieldId
      });
      navigate({
        pathname: ROUTES_PATHS.agriculturalWorkDoc,
        search: str
      });
    }
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      handleBack
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_CULTIVAR_FIELD_WORK);
  }, []);

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const data = {
      work: values.work?.id as number,
      fertilizers: values.fertilizers.map(serializerChemistry),
      pesticides: values.pesticides.map(serializerChemistry),
      cultivar_field: Number(cultivarFieldId),
      start_date: values.start_date ?  dayjs(values.start_date).format("YYYY-MM-DD") : undefined,
      end_date: values.end_date ? dayjs(values.end_date).format("YYYY-MM-DD") : undefined,
    };
    const promise = id
      ? api.agriculturalLabour.patchCultivarFieldWork(id, data)
      : api.agriculturalLabour.postCultivarFieldWork(data);
    promise
      .then(() => {
        handleBack()
        mutateList && mutateList();
        id && mutate();
      })
      .catch((error) => {
        showToast(error);
      })
      .finally(() => {
        mapStore.setInitialValuesForms({});
        helpers.setSubmitting(false);
      });
  };

  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return null;
  }

  const values = id
    ? serializeValues(data)
    : initialValues;

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      component={() => <WorkForm id={id} cultivar={cultivar} plot={plot}/>}
    />
  );
};

export default AddCultivarFieldWork;
