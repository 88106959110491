import React from "react";
import { Box, Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { IOrder } from "@/api/interfaces/responses";
import { OrderAutocomplete } from "@/components/Autocompletes";
import { AxiosError } from "axios";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";

const FIELDS_NAME = {
  FROM_ORDER: "from_order",
  TO_ORDER: "to_order",
} as const;

interface IFormik {
  [FIELDS_NAME.FROM_ORDER]: null | IOrder;
  [FIELDS_NAME.TO_ORDER]: null | IOrder;
}

interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    isCrop?: boolean;
    handleAccept: (
      from: number,
      to: number,
    ) => Promise<void>;
  };
}

const CopyOrder: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps,
}) => {
  const { toastStore } = useStores();
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    if (
      values.from_order !== null &&
      values.to_order !== null
    ) {
      helpers.setSubmitting(true);
      modalProps
        .handleAccept(
          values.from_order.id,
          values.to_order.id,
        )
        .then(() => {
          handlePop();
        })
        .catch((error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error"
            }
          })
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };
  return (
    <Formik<IFormik>
      initialValues={{
        from_order: null,
        to_order: null,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Box p={3}>
            <Typography variant="h2">
              Копировать приказ
            </Typography>
          </Box>
          <Divider />
          <Box p={3}>
            <Box mb="30px">
              <Box mb="20px">
                <Typography variant="p16">
                  Выберите приказ, из которого будут взяты
                  культуры.
                </Typography>
              </Box>
              <OrderAutocomplete
                name={FIELDS_NAME.FROM_ORDER}
                nameForClear={FIELDS_NAME.TO_ORDER}
                required={true}
                q={{
                  order_type: modalProps.isCrop
                    ? "cultivar"
                    : "hp_grouping",
                }}
                isModified
              />
            </Box>
            <Box>
              <Box mb="20px">
                <Typography variant="p16" mb="20px">
                  Выберите новый приказ, в который будут
                  перенесены культуры.
                </Typography>
              </Box>
              <OrderAutocomplete
                name={FIELDS_NAME.TO_ORDER}
                label="Новый приказ"
                required={true}
                q={{
                  exclude_ids: values[FIELDS_NAME.FROM_ORDER]?.id,
                  order_type: modalProps.isCrop
                  ? "cultivar"
                  : "hp_grouping",
                }}
                isModified
                disabled={!values[FIELDS_NAME.FROM_ORDER]}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            p={3}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              color="red"
              onClick={handleClose}
            >
              Отмена
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !values.to_order ||
                !values.from_order ||
                isSubmitting
              }
            >
              Сохранить
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CopyOrder;
