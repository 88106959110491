import React from "react";
import { Typography } from "@mui/material";
import Description from "../Description";
import Survey from "../Survey";
import { Stack } from "@mui/system";

const Methodology = () => {
  return (
    <Stack pr={5} spacing={3}>
      <Typography variant="h3">Анкета</Typography>
      <Survey/>
      <Typography variant="h3">Описание</Typography>
      <Description/>
    </Stack>
  );
};

export default Methodology;