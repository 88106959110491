import React from "react";
import { Formik, FormikHelpers } from "formik";
import { Box, Divider, Typography } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";

import AddInvestmentForm from "./components/AddInvestmentForm";
import { FIELDS_NAME, IFormik, validationSchema } from "./const";
import { observer } from "mobx-react";
import { SWR_KEYS } from "@/const";

/**
 * Компонент модалки добавления инвестиции для внебюджета
 * @param props
 * @param props.id - id плана внебюджета
 */

interface IProps {
  modalProps: {
    id: number;
    com_plan_fact: number;
    initialValues: IFormik;
    title?: string;
    isEdit?: boolean;
  };
}

const AddInvestment: React.FC<IProps> = (props) => {
  const { api, toastStore, modalStore, swrStore } = useStores();
  const { modalProps } = props;

  const initialValues = {
    [FIELDS_NAME.NAME]: modalProps.initialValues?.name ?? "",
    [FIELDS_NAME.QUANTITY]: modalProps.initialValues?.quantity ?? "",
    [FIELDS_NAME.PRICE]: modalProps.initialValues?.price ?? "",
  };

  const mutateOffBudget =
    swrStore.mutators[SWR_KEYS.getOffBudgetById(modalProps.com_plan_fact)];

  const mutateInvestments =
    swrStore.mutators[SWR_KEYS.getInvestmentList(modalProps.com_plan_fact)];

  const handleMutates = (): void => {
    mutateOffBudget?.();
    mutateInvestments?.();
  };

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const payload = {
      id: modalProps.id,
      com_plan_fact: modalProps.com_plan_fact,
      name: values.name,
      quantity: values.quantity,
      price: values.price.toString().replaceAll(" ", ""),
    };

    if (modalProps.isEdit) {
      api.offBudget
        .patchInvestment(modalProps.id, { ...payload })
        .then(() => {
          handleMutates();
          modalStore.pop();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    } else {
      api.offBudget
        .addInvestment(payload)
        .then(() => {
          handleMutates();
          modalStore.pop();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };

  const handleClose = () => {
    swrStore.mutators[SWR_KEYS.getInvestmentList(modalProps.com_plan_fact)]();
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {modalProps.title ? modalProps.title : "Добавить капитальные вложения"}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => (
          <AddInvestmentForm handleClose={handleClose} {...props} />
        )}
      />
    </Box>
  );
};

export default observer(AddInvestment);
