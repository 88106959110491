import * as yup from "yup";

type TVariant = {
  id: number;
  index: number;
  severity: string;
};

type TTrial = "hp" | "oos";

export type TForm = {
  name: string;
  type_trial: TTrial;
  variants: TVariant[];
  isEdit?: boolean;
  non_field_errors: string;
  is_parameter?: boolean;
};

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    indicatorId?: number;
    initialValues?: Partial<TForm>;
    isEdit?: boolean;
    is_parameter?: boolean;
  };
  onSubmit?: () => void;
}

export enum INDICATOR_FIELD_NAME {
  NAME = "name",
  IS_PARAMETER = "is_parameter",
  TYPE_TRIAL = "type_trial",
  VARIANTS = "variants",
}

const variantsValidationSchema = yup.array().of(
  yup.object().shape({
    index: yup
      .number()
      .max(2147483647)
      .when(
        ["$type_trial", "$isEdit"],
        ([type_trial, isEdit], schema) => {
          return type_trial === "oos" && !isEdit
            ? schema.required()
            : schema.nullable();
        },
      ),
    severity: yup
      .string()
      .trim()
      .when("$isEdit", ([isEdit], schema) => {
        return isEdit === true
          ? schema.nullable()
          : schema.required();
      }),
  }),
);

export const validationSchema = yup.object().shape({
  [INDICATOR_FIELD_NAME.TYPE_TRIAL]: yup
    .string()
    .required(),
  [INDICATOR_FIELD_NAME.NAME]: yup
    .string()
    .trim()
    .required(),
  [INDICATOR_FIELD_NAME.VARIANTS]: variantsValidationSchema,
});
