import React, { useEffect, useMemo, useState } from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { FILTERS_TYPE, PAGE_SIZE, TRIAL_TYPE } from "@/const";
import { IVariety } from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    currentCultivar: { id: number, name: string }
    onSubmit: (payload: IVariety[]) => void
    initialValue?: IVariety[]
    key: string
    subTitle?: string;
    groupId?: number;
    trialType?: string;
  };
}

const Q = {
  IN_REGISTER: "in_register",
  PAGE: "page",
  SEARCH: "search",
  FIRST_IDS: "first_ids",
  SIMILAR_GROUP_PLAN: "similar_group_plan",
} as const;

interface IArgs {
  [Q.PAGE]: number;
  [Q.SEARCH]: string;
  [Q.IN_REGISTER]: boolean | undefined;
  [Q.FIRST_IDS]: string;
  [Q.SIMILAR_GROUP_PLAN]: number | undefined;
}

/** Модалка с выбором Стандарта */

const SelectSimilarVariety: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { modalStore, api, userStore } = useStores();
  const isTrialTypeOOS = modalProps.trialType === TRIAL_TYPE.OOS
  const [q, setQ] = useState<Partial<IArgs>>({
    [Q.IN_REGISTER]: true,
    [Q.FIRST_IDS]: modalProps?.initialValue ? modalProps.initialValue.map(el => el.id).toString() : undefined
  });
  const handleAccept = (value: IVariety[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (q: IArgs) => {
    return api.regulatoryInfo.getVarietyList({
      page: q.page,
      page_size: PAGE_SIZE,
      search: q.search,
      in_register: q.in_register || undefined,
      first_ids: q.first_ids,
      similar_group_plan: isTrialTypeOOS ? modalProps.groupId : undefined,
    });
  };
  const handleSubmit = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: Array.isArray(modalProps.initialValue) ? [...modalProps.initialValue, payload] : [payload]
    });
  };
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: { cultivar: modalProps.currentCultivar },
      isStatement: true,
      cultivarEditable: false
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add
  );

  useEffect(() => {
    const updateQ = () => {
      setQ((prev) => ({
        ...prev,
        [Q.SIMILAR_GROUP_PLAN]: isTrialTypeOOS ? modalProps.groupId : undefined,
      }));
    };
  
    updateQ();

  }, [modalProps.groupId]);

  const filters = useMemo(() => [
    {
      type: FILTERS_TYPE.CHECKBOX,
      name: Q.IN_REGISTER,
      label: "В реестре",
      checked: q.in_register,
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setQ(prev => ({
          ...prev,
          [Q.IN_REGISTER]: e.target.checked
        }));
      }
    }
  ], [q])

  const props = {
    initialValue: modalProps.initialValue,
    key: ["GET_VARIETY", modalProps.currentCultivar.id],
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    listTitle: "Сорта",
    filters,
    q,
    title: "Выберите похожий сорт",
    subTitle: modalProps.subTitle,
    acceptButtonLabel: "Добавить",
    saveButtonLabel: "Добавить",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
  };

  return (
    <AddEntities<IVariety>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectSimilarVariety;