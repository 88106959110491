import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import { useSearchParams, } from "react-router-dom";
import BaseTable from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";

import {
  ICultivarFieldVariety,
} from "@/api/interfaces/responses";

import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";

import { columns } from "../models";
import {
  isUndefined,
  downloadFile,
  errorToString
} from "@/utils/helpers";
import {
  TCultivarFieldVarietyKeys,
  ICultivarFieldVarietyQueryParams
} from "../interfaces";
import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
} from "../const";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
  TRIAL_TYPE,
} from "@/const";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import Filters from "@/components/Filters";
import useFilters
  from "@/pages/CultivarFieldVariety/hooks/useFilters";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { TOAST_TYPES } from "@/apps/Toast";
import { reqInterface } from "@/api/interfaces";
import { AxiosError } from "axios";

/**
 * Компонент, представляющий таблицу Сортоиспытания
 * @returns {ReactElement}
 */
const Table = (): ReactElement => {
  const {
    api,
    swrStore,
    modalStore,
    queryStringSidebarCollector,
    toastStore
  } =
    useStores();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<ICultivarFieldVarietyQueryParams>();
  const filters = useFilters();
  const { handleOpen } = useRightSidebar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      _key: SWR_KEYS.getCultivarFieldVarietyList(),
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query?.search,
      cultivar_field__cultivar: query?.cultivar?.key,
      trial__year_of_testing: query?.year,
      status: query.status?.key,
      is_lab: false,
      is_expert_assessment: false,
    },
    api.agriculturalLabour.getCultivarFieldVarietyList,
  );

  swrStore.addMutator(SWR_KEYS.getCultivarFieldVarietyList(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TCultivarFieldVarietyKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean = Object.keys(query).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY,
      },
    });
    setSearchParams(str);
  };

  const showToast = (error: AxiosError) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleDownload = (fetcher: (params: reqInterface.DownloadCultivarFieldSowingReportReq) => Promise<Blob>, name: string) => (values, helpers: FormikHelpers<FormikValues>) => {
    helpers.setSubmitting(true);
    const payload = {
      cultivar: values.cultivar?.id as number,
      branch: values.branch?.id as number,
      plot: values.plot?.id as number,
      year: values.year as number,
    };
    fetcher(payload).then(data => {
      downloadFile(data, name);
    }).catch((error) => {
      showToast(error);
    }).finally(() => {
      helpers.setSubmitting(false);
      modalStore.close();
    });
  };

  const handleClickFile = (fetcher: (params: reqInterface.DownloadCultivarFieldSowingReportReq) => Promise<Blob>, name: string, isSowing: boolean, type: string) => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_CULTIVAR_FIELD, {
      theme: THEME_MODAL.W_555,
      title: name,
      isSowing,
      type,
      handleSubmit: (values, helpers: FormikHelpers<FormikValues>) => {
        handleDownload(fetcher, name)(values, helpers);
      }
    });
    handleClose()
  };

  const files = [
    {
      title: "Отчет о закладке опыта (ХП)",
      fetcher: api.agriculturalLabour.downloadSowingReportHp,
      type: TRIAL_TYPE.HP,
      isSowing: true,
    },
    {
      title: "Отчет о закладке опыта (ООС)",
      fetcher: api.agriculturalLabour.downloadSowingReportOos,
      type: TRIAL_TYPE.OOS,
      isSowing: true,
    },
    {
      title: "Отчет о закладке опыта (Фитопатология)",
      fetcher: api.agriculturalLabour.downloadSowingReportPhyto,
      type: TRIAL_TYPE.PHYTO,
      isSowing: true,
    },

    {
      title: "Основные показатели испытываемых сортов за год (ХП)",
      fetcher: api.agriculturalLabour.downloadVarietyMetrics,
      type: TRIAL_TYPE.HP,
      isSowing: false,
    },
    {
      title: "Основные показатели испытываемых сортов за год (Фитопатология)",
      fetcher: api.agriculturalLabour.downloadResultsSummary,
      type: TRIAL_TYPE.HP,
      isSowing: false,
    }
  ];


  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
        }}>
          <Filters
            filters={filters}
            defaultSearch={query.search}
          />
          <Button
            color="blue"
            onClick={handleClick}
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            Документы
          </Button>
        </Box>
      </Box>

      <BaseTable<ICultivarFieldVariety, TCultivarFieldVarietyKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_TEXT}
        onRowClick={handleOpenRightSidebar}
        overflowX="scroll"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {
          files.map((file, index) => (
            <MenuItem
              key={index}
              onClick={() => handleClickFile(file.fetcher, file.title, file.isSowing, file.type)}>
              {file.title}
            </MenuItem>
          ))
        }
      </Menu>
    </Fragment>
  );
};

export default Table;
