// import { resInterface } from "@/api/interfaces";
import { transformEmptyToUndefined } from "../Employee/util";
import { FIELD_NAME, TForm } from "./types";
import { ISubjectFull } from "@/api/interfaces/responses";

/**
 * Преобразования данных из запроса в форму и из формы в запрос
 */

export const mapValueToForm = (value: ISubjectFull) => {
  return {
    [FIELD_NAME.EMAILS]: value?.emails?.length
    ? value?.emails?.map((i, index) => ({
      id: index,
      email: i.email,
    }))
    : [{ id: 0, email: "" }],
    [FIELD_NAME.PHONES]: value?.phones?.length
    ? value?.phones?.map((i, index) => ({
      id: index,
      phone: i.phone,
      name: i.name,
    }))
    : [{ id: 0, phone: "", name: "" }],
    [FIELD_NAME.FIRST_NAME]: value?.first_name || '',
    [FIELD_NAME.LAST_NAME]: value?.last_name || '',
    [FIELD_NAME.COMPANY_NAME]: value?.company_name || '',
    [FIELD_NAME.SECOND_NAME]: value.second_name || '',
    [FIELD_NAME.INN]: value?.inn || null,
    [FIELD_NAME.BANK_INN]:  value?.bank || null,
    [FIELD_NAME.SNILS]: value?.snils || null,
    [FIELD_NAME.SUBJECT_TYPE]: value?.subject_type,
    [FIELD_NAME.OKPO]: value?.okpo || '',
    [FIELD_NAME.KPP]: value?.kpp || '',
    [FIELD_NAME.OGRN]: value?.ogrn || '',
    [FIELD_NAME.BANK]: value.bank || '',
    [FIELD_NAME.CHECKING_ACCOUNT]: value?.checking_account,
    [FIELD_NAME.BIK]: value?.bik || '',
    [FIELD_NAME.CORRESPONDENT_ACCOUNT]: value?.correspondent_account || '',
    [FIELD_NAME.ADDRESS]: value?.address ? {
      ...value.address,
      id: value?.address?.id,
      name: value?.address?.one_line
    } : null,
    [FIELD_NAME.LEGAL_ADDRESS]: value?.legal_address ? {
      ...value.legal_address,
      name: value?.legal_address?.one_line,
    } : null,
    [FIELD_NAME.ACTUAL_ADDRESS]: value?.actual_address ? {
      ...value.actual_address,
      name: value?.actual_address?.one_line
    } : null,

  }
}

export const mapFormToValue = (values: TForm) => {
  const emails = values.emails.map((i) => ({
    email: i.email,
  }));
  const phones = values.phones.map((i) => ({
    name: i.name as string,
    phone: i.phone,
  }));

  const modificatedPhones = phones.map((phone) => transformEmptyToUndefined(phone)).filter((phone) => phone.phone !== undefined)
  const modificatedEmails = emails.map((email) => transformEmptyToUndefined(email)).filter((email) => email.email !== undefined)

  return {
    ...values,
    [FIELD_NAME.ACTUAL_ADDRESS]: values?.actual_address?.id || null,
    [FIELD_NAME.LEGAL_ADDRESS]: values?.legal_address?.id || null,
    [FIELD_NAME.PHONES]: modificatedPhones,
    [FIELD_NAME.EMAILS]: modificatedEmails,
    [FIELD_NAME.ADDRESS]: values?.address?.id || null,
    [FIELD_NAME.BANK_INN]: values?.bank_inn || null,
    [FIELD_NAME.INN]: values.inn || null,
    [FIELD_NAME.SNILS]: values.snils || null,
  };
};
