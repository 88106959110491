import { useStores } from "@/hooks";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import { EMPLOYEE_FIELD_NAME, TForm } from "../../../const";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { StyledChip } from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";


export const SubdivisionBranch: React.FC = ({modalProps}:any) => {
  const form = useFormikContext<TForm>();
  const { modalStore } = useStores();

  const handleAddBranch = () => {
    modalStore.open(VARIANT_MODAL.SELECT_BRANCHES, {
      onSubmit: handleSelectPlot,
      initialValue: form.values.branch
    });
  };

  const handleSelectPlot = (payload) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        [EMPLOYEE_FIELD_NAME.BRANCH]: payload,
      },
    });
  };

  const handleDeletePlot = (id: number) => {
    const newChip =
    form.values.branch &&
    form.values.branch.filter(item => item.id !== id);
  form.setFieldValue(EMPLOYEE_FIELD_NAME.BRANCH, newChip);
  };

  return (
    <Field name={EMPLOYEE_FIELD_NAME.BRANCH}>
      {({ field, meta }) => (
        <FormControl>
          <FormLabel>Выберите Филиал</FormLabel>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {field.value ?
              field.value.map((item) => (
                <StyledChip
                  key={item.id}
                  size="small"
                  label={item?.name}
                  onDelete={() => handleDeletePlot(item?.id)}
                  variant="outlined"
                />
              )): null}
            <AddChip
              onClick={handleAddBranch}
            />
          </Stack>
          <FormHelperText error={meta.touched && !!meta.error}>
            {meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};