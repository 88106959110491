import React from "react";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { YearDatePicker } from "@/components/Fields";
import * as Yup from 'yup';

const FIELDS_NAME = {
  YEAR: "year",
} as const;

interface IFormik {
  [FIELDS_NAME.YEAR]: number | null;
}

const YEAR = {
  MIN: 1900,
  MAX: 2500,
}

const validationSchema = Yup.object().shape({
  [FIELDS_NAME.YEAR]: Yup.number().min(YEAR.MIN).max(YEAR.MAX).required(),
});

interface IProps {
  handleClose: () => void;
  modalProps: {
    handleSubmit: (values: IFormik,
      helpers: FormikHelpers<FormikValues>) => void
  };
}

const DownloadReport: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {

  const currentYear = new Date().getFullYear();

  return (
    <Formik<IFormik>
      validationSchema={validationSchema}
      initialValues={{
        year: currentYear,
      }}
      onSubmit={modalProps.handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} noValidate>

          <Box p={3}>
            <Typography variant="h2">
              Выбрать год
            </Typography>
          </Box>
          <Divider />
          <Box p={3}>
            <YearDatePicker name={FIELDS_NAME.YEAR} fullWidth={true} label="Год" required />
          </Box>
          <Divider />
          <Box
            p={3}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              color="red"
              onClick={handleClose}
            >
              Отмена
            </Button>


            <Box
              display="flex"
              alignItems="center"
              gap="10px"        
            >
              {isSubmitting ? (
                <CircularProgress sx={{
                  width: "20px",
                  height: "20px",
                }}/>
              ) : (
                null
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Подготовка отчета...' : 'Сохранить'}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DownloadReport;
