import React from "react";
import { Grid, Typography, Box, useTheme, useMediaQuery, Button } from "@mui/material";
import { IRegionMain } from "@/api/interfaces/responses";
import { useIntl, useStores } from "@/hooks";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { downloadFile, errorToString } from "@/utils/helpers";
import { reqInterface } from "@/api/interfaces";
import { FormikHelpers, FormikValues } from "formik";
import { FILE } from "../const";

const StatsGrid: React.FC<Pick<IRegionMain, 'totals'>> = ({totals}) => {
  const { numberFormat } = useIntl()
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { api, modalStore, toastStore } = useStores();

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleDownload = (fetcher: (params: reqInterface.DownloadMainPageInfoReq) => Promise<Blob>) => (values, helpers: FormikHelpers<FormikValues>) => {
    helpers.setSubmitting(true);
    const payload = {
      year: values.year as number,
    };
    fetcher(payload).then(data => {
      downloadFile(data, FILE.NAME, FILE.FILE_EXTENSION);
    }).catch((error) => {
      showToast(error);
    }).finally(() => {
      helpers.setSubmitting(false);
      modalStore.close();
    });
  };

  const handleClickFile = (fetcher: (params: reqInterface.DownloadMainPageInfoReq) => Promise<Blob>) => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_REPORT, {
      theme: THEME_MODAL.W_555,
      handleSubmit: (values, helpers: FormikHelpers<FormikValues>) => {
        handleDownload(fetcher)(values, helpers);
      }
    });
  };

  const gridData = [
    { label: "Регионы:", value: totals.region || "-" },
    { label: "Филиалы:", value: totals.branch || "-" },
    { label: "ГСУ:", value: totals.plot || "-" },
    {
      label: "Общая численность:",
      value: `${totals.staffing} чел`,
    },
    { label: "Общая площадь:", value: `${numberFormat(totals.total_area)} га` },
    { label: "Техника:", value: totals.machinery || "-" },
    {
      label: "",
      value: (
        <Button
          onClick={() => handleClickFile(api.regulatoryInfo.downloadMainPageInfo)}
          color="blue"
          variant="text"
          sx={{
            whiteSpace: "nowrap",
            marginTop: '5px'
          }}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Сводный отчет 
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        padding: "16px",
        borderRadius: "5px",
      }}
    >
      <Grid container spacing={2}>
        {gridData.map((item, index) => (
          <Grid
            item
            xs={4}
            sm={4}
            lg={1.6}
            xl={1.6}
            key={index}
            sx={{
              position: "relative",
              paddingLeft: index > 0 ? "16px" : "0",
            }}
          >
            {index > 0 && !(isXsScreen || isSmScreen && (index === 3 || index === 6)) && (
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  opacity: "0.5",
                  top: "60%",
                  transform: "translateY(-50%)",
                  height: "80%",
                  borderLeft: "1px solid grey",
                }}
              />
            )}
            <Typography variant="body2">
              {item.label}
            </Typography>
            <Typography variant="h4">
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatsGrid;

