import React, { Fragment } from "react";
import {
  FastField,
  Field,
  Form,
  useFormikContext,
} from "formik";
import { useStores } from "@/hooks";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import {
  INDICATOR_FIELD_NAME,
  IProps,
  TForm,
} from "../const";
import { OOSVariantFieldArray } from "./OOSVarianFieldArray";
import { HPVariantFieldArray } from "./HPVariantFieldArray";
import theme from "@/theme";

/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const IndicatorForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.indicatorId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Признак</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <FastField name={INDICATOR_FIELD_NAME.TYPE_TRIAL}>
            {({ field }) => (
              <FormControl>
                <RadioGroup {...field}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                  >
                    <FormLabel>Вид испытаний: </FormLabel>
                    <FormControlLabel
                      value={"oos"}
                      control={<Radio color="secondary" />}
                      label="ООС"
                    />
                    <FormControlLabel
                      value={"hp"}
                      control={<Radio color="secondary" />}
                      label="ХП"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </FastField>

          {form.values.type_trial === "hp" ? (
            <FastField name={INDICATOR_FIELD_NAME.IS_PARAMETER}>
              {({ field }) => (
                <FormControlLabel
                  {...field}
                  sx={{
                    width: 'fit-content',
                    '& .MuiFormControlLabel-label': {
                      color: theme.palette.blackAndWhite.gray,
                    }
                  }}
                  checked={form.values.is_parameter}
                  control={<Checkbox 
                    sx={{ color: "red" }} 
                  />}
                  label='Параметр'
                />
              )}
            </FastField>
          ) : null}

          {!modalProps?.indicatorId ? (
            <>
              <Field name={INDICATOR_FIELD_NAME.NAME}>
                {({ field, meta }) => (
                  <FormControl required>
                    <TextField
                      {...field}
                      label="Название признака"
                      fullWidth
                      size="small"
                      required
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      value={
                        form.values[
                        INDICATOR_FIELD_NAME.NAME
                        ]
                      }
                      disabled={meta.isSubmitting}
                      error={meta.touched && !!meta.error}
                    />
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>

              {form.values.type_trial === "oos" ? (
                <OOSVariantFieldArray />
              ) : (
                <HPVariantFieldArray />
              )}
            </>
          ) : (
            <Field name={INDICATOR_FIELD_NAME.NAME}>
              {({ field, meta }) => (
                <FormControl required>
                  <TextField
                    {...field}
                    label="Название признака"
                    fullWidth
                    size="small"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={
                      form.values[INDICATOR_FIELD_NAME.NAME]
                    }
                    disabled={meta.isSubmitting}

                  />
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
          )}
        </Stack>

        {form.errors?.non_field_errors &&
          <Alert
            severity="error">
            {form.errors?.non_field_errors}
          </Alert>
        }

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default IndicatorForm;
