import { Formik } from "formik";
import React from "react";
import AddVarietyIndicatorForm from "./components/AddVarietyIndicatorForm";
import { FIELD_NAME, IFormik, serializedValues, validationSchema } from "./const";
import { useStores } from "@/hooks";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { EditIcon } from "@/components/icons";
import theme from "@/theme";
import { SWR_KEYS } from "@/const";
import { IVarietyIndicatorRes } from "@/api/interfaces/responses";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    indicatorType: string;
    varietyId: number;
    data?: IVarietyIndicatorRes;
  };
}

interface IPayload {
  variety: number;
  indicator_type: string;
  variant_indicator_methodology: number;
}

const AddVarietyIndicator: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { varietyId, indicatorType, data, } = modalProps;
  const { api, toastStore, modalStore, swrStore } = useStores();
  const defaultValues = {
    [FIELD_NAME.VARIETY]: varietyId,
    [FIELD_NAME.INDICATOR_TYPE]: indicatorType,
    [FIELD_NAME.INDICATOR_METHODOLOGY]: null,
    [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]: null,
  }
  const values = data ? serializedValues(data, varietyId, indicatorType) : defaultValues
  const mutateList = swrStore.mutators[SWR_KEYS.getVarietyIndicator(varietyId, indicatorType)];

  const fetcher = (
    payload: IPayload      
  ) => {
    if (!data) return api.regulatoryInfo.postVarietyIndicator(payload)
    return api.regulatoryInfo.patchVarietyIndicatorById(payload, data.id);
  }

  const handleSubmit = (value: IFormik) => {

    const payloadPost = {
      [FIELD_NAME.VARIETY]: value[FIELD_NAME.VARIETY],
      [FIELD_NAME.INDICATOR_TYPE]: value[FIELD_NAME.INDICATOR_TYPE],
      [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]: value[FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]?.id as number,
    }

    fetcher(payloadPost).then(() => {
      mutateList && mutateList()
      handleClose();
    })
      .catch((e) => {
        toastStore.createToast(e);
      })
      .finally(() => {
        modalStore.close();
      });
  }

  return (

    <Box>
      <Stack
        direction={"row"}
        alignItems={"flex-end"}
        gap={2}
        pb={2}
        p={3}
      >
        <Typography color={theme.palette.blackAndWhite.gray}>
          <EditIcon />
        </Typography>
        <Box>
          <Typography variant="h2" fontSize={10} color={theme.palette.blackAndWhite.gray}>
            Сорт
          </Typography>
          <Typography variant="h2" fontSize={24}>
            Добавить признак
          </Typography>
        </Box>
      </Stack>
      <Divider />

      <Formik<IFormik>
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        component={() =>
          <AddVarietyIndicatorForm
            modalProps={modalProps}
            handleClose={handleClose} />
        }
      >
      </Formik>
    </Box>
  )
}

export default AddVarietyIndicator;
