import React, { useEffect } from "react";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Loader from "@/components/Loader";
import TableAccordion
  from "@/components/Accordions/TableAccordion";
import DistributionGroup from "../DistributionGroup";
import DistributionNonGroup from "../DistributionNonGroup";
import {
  IPaginatedResult,
  IPlanGroupPlot
} from "@/api/interfaces/responses";
import { TPlanningTrialType } from "@/api/types";

interface IProps {
  planPlotId: number;
  cultivarId: number;
  trialType: TPlanningTrialType;
  year: number | string;
}

const DistributionStep: React.FC<IProps> = ({
  planPlotId,
  cultivarId,
  trialType,
  year
}) => {
  const { api, swrStore, planPlotStore } = useStores();

  const key = SWR_KEYS.getPlanGroupPlotList();
  const getKey = (index: number) => ({
    _key: key,
    page: index + 1,
    page_size: 15,
    plan_plot: planPlotId,
  });

  const {
    data: groupData,
    error,
    isLoading,
    mutate,
    handleScroll
  } =
    useInfiniteScroll(
      getKey,
      api.planning.getPlanGroupPlotList,
      {
        revalidateFirstPage: false,
        revalidateOnMount: true,
        onSuccess: (data: IPaginatedResult<IPlanGroupPlot>[]) => {
          const results = data ? data.map((res) => res.results).flat() : [];
          planPlotStore.setPlanPlotGroups(results);
        }
      }
    );

  useEffect(() => {
    swrStore.addMutator(key, mutate);
  }, []);

  if (isLoading) {
    return (
      <Loader/>
    );
  }
  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  return (
    <Box pr={3} mr={-3} onScroll={handleScroll} sx={{
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      maxHeight: "500px"
    }}
         gap="20px">
      <Typography variant="h3">
        Распределение
      </Typography>
      <Stack direction="column" gap="20px">
        {
          groupData.map((el, index) => {
            const subtitle = el.variants.map(item => item.severity).join(", ");
            const groupNumber = `Группа ${index + 1}`
            return (
              <TableAccordion key={el.id}
                              title={groupNumber}
                              subtitle={subtitle}
                              slotProps={{ transition: { unmountOnExit: true } }}>
                <DistributionGroup groupId={el.id}
                                   planPlotId={el.plan_plot}
                                   cultivarId={cultivarId}
                                   trialType={trialType}
                                   groupNumber={groupNumber}
                                   variants={el.variants}
                />
              </TableAccordion>
            );
          })
        }
      </Stack>
      <TableAccordion
        title="Сорта без групп"
        slotProps={{ transition: { unmountOnExit: true } }}>
        <DistributionNonGroup cultivarId={cultivarId}
                              trialType={trialType}
                              planPlotId={planPlotId}
                              year={year}
        />
      </TableAccordion>
    </Box>
  );
};

export default DistributionStep;