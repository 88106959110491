export const ADDRESS_HEADER_LABELS = {
  one_line: 'Адрес',
  editing: "меню",
};

export const ADDRES_KEYS = {
  one_line: 'one_line',
  editing: "меню",
} as const;

export const LIGHT_ZONE_HEADER_LABELS = {
  number: "Арабский номер",
  rome_number: "Римский номер",
  comment: "Комментарий",
  editing: "меню",
};

export const LIGHT_ZONE_KEYS = {
  number: "number",
  rome_number: "rome_number",
  comment: "comment",
  editing: "меню",
} as const;

export const CULTIVAR_HEADER_LABELS = {
  name: "Название",
  department: "Отдел",
  editing: "меню",
};

export const CULTIVAR_KEYS = {
  name: "name",
  department: "department",
  editing: "меню",
} as const;

export const COUNTRIES_HEADER_LABELS = {
  iso: "Код ISO",
  name: "Название",
  full_name: "Полное название",
  editing: "меню",
};

export const COUNTRIES_KEYS = {
  iso: "iso",
  name: "name",
  full_name: "full_name",
  editing: "меню",
} as const;

export const UNITS_HEADER_LABELS = {
  name: "Название",
  full_name: "Описание",
  editing: "меню",
};

export const UNITS_KEYS = {
  name: "name",
  full_name: "full_name",
  editing: "меню",
} as const;

// приказы
export const ORDER_HEADER_LABELS = {
  name: "Название",
  number: "Номер",
  order_date: "Дата приказа",
  order_type: "Тип приказа",
  editing: "меню",
};

export const ORDER_KEYS = {
  name: "name",
  number: "number",
  order_date: "order_date",
  order_type: "order_type",
  editing: "меню",
} as const;

// заболевания культур

export const DISEASES_HEADER_LABELS = {
  name: "Название",
  cultivar__name: "Культура",
  description: "Описание",
  editing: "меню",
};

export const DISEASES_KEYS = {
  name: "name",
  cultivar__name: "cultivar__name",
  description: "description",
  editing: "меню",
} as const;

export const POSTS_HEADER_LABELS = {
  name: "Название",
  full_name: "Полное название",
  editing: "меню",
};

export const POSTS_KEYS = {
  name: "name",
  full_name: "full_name",
  editing: "меню",
} as const;

// Количество по разнарядке

export const QUANTITY_HEADER_LABELS = {
  type: "Тип",
  cultivar: "Культура",
  material_type: "Тип материала",
  quantity: "Кол-во",
  editing: "меню",
};

export const QUANTITY_KEYS = {
  type: "type",
  cultivar: "cultivar",
  material_type: "material_type",
  quantity: "quantity",
  editing: "меню",
} as const;

// Пошлины

export const DUTY_HEADER_LABELS = {
  number: "Номер",
  name: "Название",
  resident: "Для резидента",
  non_resident: "Для нерезидента",
  editing: "меню",
};

export const DUTY_KEYS = {
  number: "number",
  name: "name",
  resident: "resident",
  non_resident: "non_resident",
  editing: "меню",
} as const;

// Тех. карты

export const TECH_MAP_HEADER_LABELS = {
  name: "Название",
  cultivar__name: "Культура",
  plot__name: "ГСУ",
  editing: "меню",
};

export const TECH_MAP_KEYS = {
  name: "name",
  cultivar__name: "cultivar__name",
  plot__name: "plot__name",
  editing: "editing",
} as const;

// Сотрудники

export const EMPLOYEE_HEADER_LABELS = {
  full_name: "ФИО",
  phones: "Телефон",
  emails: "Эл. Почта",
  comment: "Комментарий",
  editing: "меню",
};

export const EMPLOYEE_KEYS = {
  last_name: "last_name",
  phones: "phones",
  emails: "emails",
  comment: "comment",
  editing: "меню",
} as const;

// Филиалы

export const BRANCHES_HEADER_LABELS = {
  name: "Название",
  region: "Регион",
  staff_director: "Начальник филиала",
  phones: "Телефоны",
  editing: "меню",
};

export const BRANCHES_KEYS = {
  name: "name",
  region__name: "region__name",
  staff_director__last_name: "staff_director__last_name",
  phones: "phones",
  editing: "menu",
} as const;

// Замечания
export const REMARK_HEADER_LABELS = {
  name: "Название",
  remark_type: "Тип замечания",
  editing: "меню",
};

export const REMARK_KEYS = {
  name: "name",
  remark_type: "remark_type",
  editing: "меню",
} as const;

// Субъекты
export const SUBJECT_HEADER_LABELS = {
  subject_type: "Тип",
  name: "Наименование",
  phones: "Телефон",
  emails: "Эл. Почта",
  editing: "меню",
};

export const SUBJECT_KEYS = {
  subject_type: "subject_type",
  name: "name",
  phones: "phones",
  emails: "emails",
  editing: "меню",
} as const;

// Типы материала
export const MATERIAL_TYPE_LABELS = {
  name: "Название",
  editing: "меню",
};

export const MATERIAL_TYPE_KEYS = {
  name: "name",
  editing: "меню",
} as const;

// организации
export const ORG_HEADER_LABELS = {
  name: "Название",
  address: "Адрес",
  editing: "меню",
};

export const ORG_KEYS = {
  name: "name",
  address: "address",
  editing: "меню",
} as const;

// Регионы
export const REGIONS_TYPE_LABELS = {
  number: "Номер",
  name: "Название",
  editing: "меню",
};

export const REGIONS_TYPE_KEYS = {
  number: "number",
  name: "name",
  editing: "меню",
} as const;

// Регионы РФ
export const DISTRICTS_LABELS = {
  name: "Название",
  light_zone: "Световая зона",
  branch: "Филиал",
  editing: "меню",
}; 

export const DISTRICTS_KEYS = {
  name: "name",
  light_zone__number: "light_zone__number",
  branch: 'branch',
  editing: "меню",
} as const;

// Отделы
export const DEPARTMENT_LABELS = {
  name: "Название",
  staff_director__last_name: "Начальник отдела",
  phones: "Телефон",
  editing: "меню",
};

export const DEPARTMENT_KEYS = {
  name: "name",
  staff_director__last_name: "staff_director__last_name",
  phones: 'phones',
  editing: "меню",
} as const;

// Репродукции
export const REPRODUCTION_LABELS = {
  name: "Название",
  description: "Описание",
  editing: "меню",
};

export const REPRODUCTION_KEYS = {
  name: "name",
  description: "description",
  editing: "меню",
} as const;

// Cultivar usage type
export const CULTIVAR_USAGE_TYPE_LABELS = {
  cultivar: 'Культура',
  name: "Название",
  product_form: "Продукция",
  editing: "меню",
};

export const CULTIVAR_USAGE_TYPE_KEYS = {
  cultivar: "cultivar",
  name: "name",
  product_form: "product_form",
  editing: "меню",
} as const;

// Cultivar group
export const CULTIVAR_GROUP_LABELS = {
  id: 'Номер',
  usage_type: 'Направление использования',
  variant: "Значение признака группы",
  editing: "меню",
};

export const CULTIVAR_GROUP_KEYS = {
  id: 'id',
  usage_type__name: "usage_type__name",
  variant: "variant",
  editing: "меню",
} as const;

// Plots
export const PLOT_LABELS = {
  name: "Название",
  branch: "Филиал",
  district: "Регион РФ",
  staff_director: "Руководитель",
  status: "Статус",
  editing: "меню",
};

export const PLOT_KEYS = {
  name: 'name',
  branch: "branch",
  district: "district",
  staff_director: "staff_director",
  status: "status",
  editing: "меню", 
} as const;

// Фазы вегетации
export const VEGETATION_STAGE_LABELS = {
  cultivar__name: "Культура",
  name: "Название",
  description: "Описание",
  editing: "меню",
};

export const VEGETATION_STAGE_KEYS = {
  cultivar__name: 'cultivar__name',
  name: "name",
  description: "description",
  editing: "меню", 
} as const;

// Vegetation Causes
export const VEGETATION_CAUSES_LABELS = {
  code: "Код",
  name: "Название",
  type_of_reason: "Тип причины",
  editing: "меню",
};

export const VEGETATION_CAUSES_KEYS = {
  code: 'code',
  name: "name",
  type_of_reason: "type_of_reason",
  editing: "меню", 
} as const;

// Expense 
export const EXPENSE_LABELS = {
  name: "Название",
  editing: "меню",
};

export const EXPENSE_KEYS = {
  name: "name",
  editing: "меню", 
} as const;

// Rate
export const RATE_LABELS = {
  name: "Название",
  editing: "меню",
};

export const RATE_KEYS = {
  name: "name",
  editing: "меню", 
} as const;


// Contracts
export const AGREEMENT_LABELS = {
  agreement_number: "Номер",
  counterparty__name: "Контрагент",
  agreement_type__name: "Тип",
  agreement_date: "Дата договора",
  expiry_date: "Срок действия",
  executive: "Ответственный",
  editing: "меню",
};

export const AGREEMENT_KEYS = {
  agreement_number: "agreement_number",
  counterparty__name: "counterparty__name",
  agreement_type__name: "agreement_type__name",
  agreement_date: "agreement_date",
  expiry_date: "expiry_date",
  executive: "executive",
  editing: "меню", 
} as const;


// Budgetery Trail Calc
export const BUDGETARY_TRAIL_CALC_LABEL  = {
  year_of_trial: "Год",
  type_of_trial: "Вид испытания",
  type_of_applicant: "Тип заявителя",
  amount_of_experiences: "Кол-во сортоопытов",
  editing: "меню",
};

export const BUDGETARY_TRAIL_CALC_KEYS = {
  year_of_trial: 'year_of_trial',
  type_of_trial: "type_of_trial",
  type_of_applicant: "type_of_applicant",
  amount_of_experiences: "amount_of_experiences",
  editing: "меню", 
} as const;

// Сорта
export const VARIETIES_LABELS = {
  code: "Код сорта",
  name: "Название",
  cultivar__name: "Культура",
  type: "Тип",
  editing: "меню",
};

export const VARIETIES_KEYS = {
  code: 'code',
  name: "name",
  cultivar__name: 'cultivar__name',
  type: 'type',
  editing: "меню",
} as const;

// Химия
export const CHEMISTRY_LABELS = {
  name: "Название",
  type: "Тип",
  editing: "меню",
};

export const CHEMISTRY_KEYS = {
  name: "name",
  type: 'type',
  editing: "меню",
} as const;

// Сельхозтехника
export const AGRICULTURAL_MACHINERY_LABELS = {
  identification_number: "Инвентарный номер",
  name: "Название",
  type: "Тип",
  location: "ГСУ",
  editing: "меню",
};

export const AGRICULTURAL_MACHINERY_KEYS = {
  identification_number: 'identification_number',
  name: "name",
  type: 'type',
  location__plot__name: 'location__plot__name',
  editing: "меню",
} as const;

// Признаки
export const INDICATORS_LABELS = {
  type_trial: "Вид испытаний",
  name: "Название признака",
  editing: "меню",
};

export const INDICATORS_KEYS = {
  type_trial: "type_trial",
  name: "name",
  editing: "меню",
} as const;

// Параметры
export const PARAMETERS_LABELS = {
  name: "Название",
  unit: "Единица измерения",
  min_parameter_value: "Минимальное значение",
  max_parameter_value: "Максимальное значение",
  editing: "меню",
};

export const PARAMETERS_KEYS = {
  name: "name",
  unit: "unit",
  min_parameter_value: "min_parameter_value",
  max_parameter_value: "max_parameter_value",
  editing: "меню",
} as const;

// methodology

// Название 'name',
// Вид испытаний  'type_trial'
// Номер 'number'
// Культура (наименование) 'cultivar'
// Дата утверждения 'confirmation_date'

export const METH_LABELS = {
  type_trial: "Вид испытаний",
  name: "Название",
  number: "Номер",
  cultivar: "Культура",
  confirmation_date: "Дата утверждения",

  editing: "меню",
};

export const METH_KEYS = {
  type_trial: "type_trial",
  name: "name",
  number: "number",
  cultivar: "cultivar",
  confirmation_date: "confirmation_date",

  editing: "меню",
} as const;
