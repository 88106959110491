import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";

import { IPlanningList, } from "@/api/interfaces/responses";

import { useQuery, useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";

import { columns } from "../models";
import { downloadFile, errorToString, isUndefined } from "@/utils/helpers";
import { TPlanningKeys } from "../interfaces";
import {
  IPlanningQueryParams
} from "@/api/interfaces/requests";
import {
  ADD_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT_WITH_FILTERS,
} from "../const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
} from "@/const";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Filters from "@/components/Filters";
import { getFilters } from "../utils";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { TOAST_TYPES } from "@/apps/Toast";
import { FormikHelpers, FormikValues } from "formik";
import { reqInterface } from "@/api/interfaces";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * Компонент, представляющий таблицу планирование ГСУ.
 * @returns {ReactElement}
 */
const PlanningTable = (): ReactElement => {
  const {
    api,
    queryStringSidebarCollector,
    swrStore,
    userStore,
    toastStore
  } =
    useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const query = useQuery<IPlanningQueryParams>();
  const [queryParams, setQueryParams] =
    useState<IPlanningQueryParams>(query);

  const location = useLocation();
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      key: SWR_KEYS.getPlanningTable(),
      search: queryParams?.search,
      trial_type: queryParams?.trial_type?.key,
      status: queryParams?.status?.key,
      year_min: queryParams?.year_min,
      year_max: queryParams?.year_max,
      expert_assessment: false
    },
    api.planning.getPlanning,
  );

  swrStore.addMutator(SWR_KEYS.getPlanningTable(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TPlanningKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean =
    Object.keys(queryParams).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {

    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      module_id: id,
      trial_type: {
        key: queryParams?.trial_type?.key,
        label: queryParams?.trial_type?.label,
      },
    });
    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      },
    });
    setSearchParams(str)
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  const { modalStore } = useStores();
  const handleAddTrial = () => {
    modalStore.open(VARIANT_MODAL.SELECT_TRIAL_PLAN);

  };
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filters = getFilters(
    [query?.date_range_after, query?.date_range_before],
    query?.status,
    query?.trial_type,
  );

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleDownload = (fetcher: (params: reqInterface.DownloadTrialsPlanHpSummaryReq) => Promise<Blob>, name: string) => (values, helpers: FormikHelpers<FormikValues>) => {
    helpers.setSubmitting(true);
    const payload = {
      cultivar: values.cultivar?.id as number,
      year: values.year as number,
    };
    fetcher(payload).then(data => {
      downloadFile(data, name);
    }).catch((error) => {
      showToast(error);
    }).finally(() => {
      helpers.setSubmitting(false);
      modalStore.close();
    });
  };

  const handleClickFile = (fetcher: (params: reqInterface.DownloadTrialsPlanHpSummaryReq) => Promise<Blob>, name: string) => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_PLANNING, {
      theme: THEME_MODAL.W_555,
      title: name,
      handleSubmit: (values, helpers: FormikHelpers<FormikValues>) => {
        handleDownload(fetcher, name)(values, helpers);
      }
    });
    handleClose()
  };


  const files = [
    {
      title: "Сводный план испытаний на ХП по филиалам",
      fetcher: api.planning.downloadTrialsBranchPlanHpSummary,
    },
    {
      title: "Сводный план испытаний на ХП по регионам",
      fetcher: api.planning.downloadTrialsRegionPlanHpSummary,
    },
    {
      title: "Сводный план испытаний на ООС по филиалам",
      fetcher: api.planning.downloadTrialsBranchPlanOosSummary,
    },
    {
      title: "Сводный план испытаний на ООС по регионам",
      fetcher: api.planning.downloadTrialsRegionPlanOosSummary,
    },
    {
      title: "План по культуре",
      fetcher: api.planning.downloadCultivarPlansHP,
    },
    // {
    //   title: "План испытаний на ООС по культуре",
    //   fetcher: api.agriculturalLabour.downloadVarietySummary,
    // },
  ];
  const canAddPlan = userStore.isPermission(
    PERMISSIONS.planningPlan,
    PERMISSION_CRUD.add
  );

  const canDownloadDoc = userStore.isPermission(
    PERMISSIONS.planningPlanDownloadedDocuments,
    PERMISSION_CRUD.view
  );

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
        }}>
          <Filters
            filters={filters}
            defaultSearch={query.search}
          />

          {canDownloadDoc &&
            <Button
              color="blue"
              onClick={handleClick}
              variant="outlined"
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Документы
            </Button>}
        </Box>

        {canAddPlan &&
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddTrial}
          >
            Добавить план
          </Button>}
      </Box>

      <Table<IPlanningList, TPlanningKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_PLANING_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_PLANING_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_PLANING_TEXT}
        onRowClick={handleOpenRightSidebar}
        overflowX="scroll"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {
          files.map((file, index) => (
            <MenuItem
              key={index}
              onClick={() => handleClickFile(file.fetcher, file.title)}>
              {file.title}
            </MenuItem>
          ))
        }
      </Menu>
    </Fragment>
  );
};

export default PlanningTable;
