import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import {
  IPropsAddCulture,
  TForm,
  CULTIVAR_FIELD_NAME,
} from "../../const";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { ICultivar } from "@/api/interfaces/responses";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import AddChip from "@/components/Chips/AddChip";
import { StyledChip } from "../../styles";
import { LocationAutoComplete, PlotAutoComplete } from "@/components/Autocompletes";

/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const CreateOBCultivarForm: React.FC<IPropsAddCulture> = ({
  modalProps,
  branchID
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();
  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR, {
      onSubmit: handleSelectCultivar,
    });
  };

  const handleSelectCultivar = (payload: ICultivar) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values,
        [CULTIVAR_FIELD_NAME.CULTIVAR]: {
          id: payload.id,
          name: payload.name,
        },
      },
    });
  };

  const acreage = formik.values.acreage
  const productivity = formik.values.productivity
  const croppage = acreage && productivity ? Number(acreage) * Number(productivity) / 10 : ""

  const handleChangePlot = (child) => {
    formik.setFieldValue(CULTIVAR_FIELD_NAME.PLOT, child);
    formik.setFieldValue(CULTIVAR_FIELD_NAME.LOCATION, "");
  };

  const handleChangeLocation = (payload) => {
    formik.setFieldValue(CULTIVAR_FIELD_NAME.LOCATION, payload);
  };

  const title = modalProps.cultivarId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />

        <Grid
          container
          direction={"column"}
          mt={1}
          spacing={1}
          p={3}
        >
          <Grid p={1}>
            <Field name={CULTIVAR_FIELD_NAME.CULTIVAR}>
              {({ field, meta }) => (
                <FormControl required>
                  <FormLabel>Культура</FormLabel>
                  <Box>
                    {field.value ? (
                      <StyledChip
                        size="small"
                        variant="outlined"
                        label={field.value.name}
                      />
                    ) : (
                      <AddChip
                        onClick={handleAddCultivar}
                      />
                    )}
                  </Box>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Stack
            p={1}
            gap={2}
          >
            <Typography fontSize={16} variant="h4">
              Участок
            </Typography>
            <PlotAutoComplete
              name={CULTIVAR_FIELD_NAME.PLOT}
              handleChange={handleChangePlot}
              q={{
                branch: branchID || modalProps?.branch,
              }}
            />
            <LocationAutoComplete
              name={CULTIVAR_FIELD_NAME.LOCATION}
              handleChange={handleChangeLocation}
              q={{
                plot: formik.values.plot?.id ?? undefined,
                branch: formik.values.branch?.id ?? undefined,
              }}
              disabled={!formik.values.plot}
              required={true}
            />
          </Stack>
          <Grid
            p={1}
            container
            direction={"column"}
            gap={"20px"}
          >
            <Grid
              container
              direction={"column"}
              gap={"20px"}
            >
              <Typography fontSize={16} variant="h4">
                Семена
              </Typography>

              <Grid container gap={3}>
                <Grid item xs={5.8}>
                  <Field
                    name={CULTIVAR_FIELD_NAME.SEEDING_RATE}
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Норма высева, кг/га"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5.8}>
                  <Field
                    name={CULTIVAR_FIELD_NAME.SEEDS_NEEDS}
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Требуется семян, т/пог. ед"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container gap={3}>
                <Grid item xs={5.8}>
                  <Field
                    name={
                      CULTIVAR_FIELD_NAME.SEEDS_AVAILABLE
                    }
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Имеется семян, т"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5.8}>
                  <Field
                    name={CULTIVAR_FIELD_NAME.SEEDS_PRICE}
                  >
                    {({ field, meta }) => (
                      <TextField
                        type="text"
                        {...field}
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Цена руб/т"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction={"column"}
              gap={"20px"}
            >
              <Typography fontSize={16} variant="h4">
                Финансовый план
              </Typography>

              <Grid container gap={3}>
                <Grid item xs={5.8}>
                  <Field name={CULTIVAR_FIELD_NAME.ACREAGE}>
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Размер посевных площадей, га"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5.8}>
                  <Field
                    name={CULTIVAR_FIELD_NAME.PRODUCTIVITY}
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Показатели урожайности, ц/га"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid container gap={3}>

                <Grid item xs={5.8}>
                  <TextField
                    type="text"
                    fullWidth
                    value={croppage ? croppage : ""}
                    label="Валовый сбор продукции, т"
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                </Grid>

                <Grid item xs={5.8}>
                  <Field
                    name={CULTIVAR_FIELD_NAME.SALES_VOLUME}
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        fullWidth
                        label="Объем реализации, т"
                      />
                    )}
                  </Field>
                </Grid>

                <Grid container gap={3}>

                  <Grid item xs={5.8}>
                    <Field name={CULTIVAR_FIELD_NAME.PRICE}>
                      {({ field, meta }) => (
                        <TextField
                          {...field}
                          type="text"
                          error={meta.touched && !!meta.error}
                          helperText={
                            meta.touched && meta.error
                          }
                          fullWidth
                          label="Цена, руб/т"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default CreateOBCultivarForm;
