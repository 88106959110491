import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import BaseApi from "./BaseApi";
import {
  DEFAULT_LINKS,
  STATUS_CODES,
  TOKEN_KEYS,
} from "@/const";
import {
  deleteAuthCookies,
  getCookie,
  setCookie,
} from "@/utils/cookie";
import { reqInterface, resInterface } from "../interfaces";
import {
  ROUTES,
  ROUTES_PATHS
} from "@/apps/AppRouter/const";

abstract class AbstractApi extends BaseApi {
  AUTH_TOKEN_KEY: string;
  MAIN_URL: string;
  refreshPromise: Promise<resInterface.IPostRefreshRes> | null;

  constructor(MAIN_URL: string) {
    super(MAIN_URL);
    this.MAIN_URL = MAIN_URL;
    this.AUTH_TOKEN_KEY = "auth_token_key";
    this.refreshPromise = null;
  }

  /** Обработка ошибок в ответе сервера.
   * @param axios Инстанс Axios.
   */
  errorHandler = (axios: AxiosInstance) => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (typeof window === "undefined") {
          console.log("SSR erorHandler");
          throw error;
        }
        if (error?.response && error?.response?.data) {
          if (
            error?.response?.status === STATUS_CODES._400
          ) {
            console.error("400");
          }

          if (
            error?.response?.status === STATUS_CODES._401
          ) {
            console.error("401");
            
            const refreshToken = getCookie(
              TOKEN_KEYS.REFRESH_TOKEN_KEY,
            );

            if (
              refreshToken !== undefined &&
              refreshToken !== ""
            ) {
              this.rewriteAccessTokenCookie(refreshToken)
                .then(() => {
                  if (
                    location.pathname === ROUTES_PATHS.login
                  ) {
                    window.location.replace(
                      ROUTES_PATHS.main,
                    );
                  } else {
                    window.location.reload();
                  }
                })
                .catch(() => {
                  deleteAuthCookies();

                  if (
                    location.pathname !== ROUTES_PATHS.login
                  ) {
                    window.location.replace(
                      ROUTES_PATHS.login,
                    );
                  }

                  console.error("401");
                });
                return error;
            } else if (
              location.pathname !== ROUTES_PATHS.login &&
              window.location.pathname !==
                DEFAULT_LINKS.error_server
            ) {
              location.replace(ROUTES_PATHS.login);
            }
          }

          if (
            error?.response?.status === STATUS_CODES._404
          ) {
            console.error("404");
            // throw error;
            window.location.href = ROUTES.Error404;
            return error;
          }

          if (
            error?.response?.status === STATUS_CODES._500 &&
            window.location.pathname !==
              DEFAULT_LINKS.error_server
          ) {
            window.location.replace(
              DEFAULT_LINKS.error_server,
            );
          }
        }

        throw error;
      },
    );
  };
  requestHandler = (axios: AxiosInstance) => {
    axios.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const accessToken = getCookie(
          TOKEN_KEYS.AUTH_TOKEN_KEY,
        );
        const isTokenValid = this.isTokenValid();
        const refreshToken = getCookie(
          TOKEN_KEYS.REFRESH_TOKEN_KEY,
        );
        const refresh_url =
          config.url && config.url.replace(this.url, "");

        if (!accessToken) {
          console.log("Нет токена");
          return config;
        }

        if (isTokenValid) {
          config.headers.Authorization = `Bearer ${accessToken}`;
          return config;
        }

        if (!refreshToken) {
          console.log("Нет рефреш токена");
          return config;
        }

        if (refresh_url === "/token/refresh/") {
          return config;
        }

        this.refreshPromise =
          this.refreshPromise ??
          this.rewriteAccessTokenCookie(refreshToken);
        const data = await this.refreshPromise;
        config.headers.Authorization = `Bearer ${data.access}`;
        this.refreshPromise = null;
        return config;
      },
    );
  };

  /** Перезапись токена доступа в куки.
   * @param refreshToken Токен обновления (refresh).
   * @returns Новый токен доступа.
   */
  rewriteAccessTokenCookie = async (
    refreshToken: string,
  ): Promise<resInterface.IPostRefreshRes> => {
    const res = await this.post<
      reqInterface.IPostRefreshReq,
      resInterface.IPostRefreshRes
    >("/token/refresh/", { refresh: refreshToken });
    setCookie(TOKEN_KEYS.AUTH_TOKEN_KEY, res.data.access);
    setCookie(
      TOKEN_KEYS.ACCESS_TOKEN_EXPIRY,
      res.data.access_token_expiry,
    );
    return res.data;
  };

  /** Настройка перед отправкой запроса. Вызывается отдельно для каждого запроса.
   * @param axios Инстанс Axios.
   * @returns Инстанс Axios.
   */
  axiosOverride = (axios: AxiosInstance): AxiosInstance => {
    this.requestHandler(axios);
    this.errorHandler(axios);

    return axios;
  };

  isTokenValid = (): boolean => {
    const accessTokenExpiry = getCookie(
      TOKEN_KEYS.ACCESS_TOKEN_EXPIRY,
    );
    return accessTokenExpiry
      ? new Date() <= new Date(accessTokenExpiry)
      : false;
  };
}

export default AbstractApi;
