import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { IRegion } from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";


export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    currentRegion: { id: number, name: string }
    onSubmit: (payload: IRegion[]) => void
    initialValue?: IRegion[]
    key: string
  };
}

/** Модалка с выбором регион */

const SelectRegions: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { modalStore, api, userStore } = useStores();
  const handleAccept = (value: IRegion[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (args) => {
    const { page, search, first_ids } = args;
    return api.regulatoryInfo.getRegionList({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids
    });
  };
  const handleSubmit = (payload: IRegion[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: payload
    });
  };

  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.ADD_REGION, {
      onSubmit: handleSubmit,
      initialValue: { regions: modalProps.currentRegion},
    });
  };
  const getLabel = (value: IRegion): string => value.name;
  const getMeta = (value: IRegion) => String(value.number);

  const isAddRegion = userStore.isPermission(
    PERMISSIONS.regulatory_infoRegion,
    PERMISSION_CRUD.add
  );

  const props = {
    initialValue: modalProps.initialValue,
    key: ["GET_REGION"],
    addButtonLabel: "Добавить регион",
    isDisabledBtnAdd: isAddRegion,
    title: "Выберите регион",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
  };

  return (
    <AddEntities<IRegion>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectRegions;