import React, { useState, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE, FILTERS_TYPE, TRIAL_TYPE, SWR_KEYS } from "@/const";
import { IMethList, IPaginatedResult, IVariantMeth, IVariety } from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { IErrorResponse } from "@/interfaces";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { Box } from "@mui/material";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    title?: string
    currentCultivar: { id: number, name: string }
    onSubmit: (payload: IVariety[]) => void
    initialValue?: IVariety[]
    key: string
  };
}

const Q = {
  IN_REGISTER: "in_register",
  PAGE: "page",
  SEARCH: "search",
  FIRST_IDS: "first_ids",
  CULTIVAR: "cultivar",
  REFERENCE_GROUP_PLAN: "reference_group_plan",
  INDICATOR_METHODOLOGY: "indicator_methodology",
} as const;

interface IArgs {
  [Q.PAGE]: number,
  [Q.SEARCH]: string,
  [Q.IN_REGISTER]: boolean | undefined
  [Q.FIRST_IDS]: string | undefined
  [Q.CULTIVAR]: number
  [Q.REFERENCE_GROUP_PLAN]: number | undefined
  [Q.INDICATOR_METHODOLOGY]: number | undefined
}

/** Модалка с выбором сорта */

const SelectVarieties: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { modalStore, api, userStore } = useStores();
  const [q, setQ] = useState<Partial<IArgs>>({
    [Q.IN_REGISTER]: true,
    [Q.CULTIVAR]: modalProps.currentCultivar.id,
    [Q.FIRST_IDS]: modalProps?.initialValue
      ? modalProps.initialValue.map((el) => el.id).toString()
      : undefined
  });
  const handleAccept = (value: IVariety[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (q: IArgs) => {
    return api.regulatoryInfo.getVarietyList({
      page: q.page,
      page_size: PAGE_SIZE,
      search: q.search,
      in_register: q.in_register || undefined,
      first_ids: q.first_ids,
      cultivar: q.cultivar,
      reference_group_plan: q.reference_group_plan,
    });
  };
  const handleSubmit = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: Array.isArray(modalProps.initialValue) ? [...modalProps.initialValue, payload] : []
    });
  };
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: { cultivar: modalProps.currentCultivar },
      isStatement: true,
      cultivarEditable: false
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const {
    data: methodologyData,
  }: SWRResponse<
    IPaginatedResult<IMethList>,
    IErrorResponse
  > = useSWR(
    {
      page_size: DEFAULT_SIZE_PAGE,
      type_trial: TRIAL_TYPE.OOS,
      key: SWR_KEYS.getMethodologyList(),
      cultivar: modalProps.currentCultivar.id,
    },
    api.regulatoryInfo.getMethodologyList,
  );

  const filters = useMemo(() => [
    {
      type: FILTERS_TYPE.CHECKBOX,
      name: Q.IN_REGISTER,
      label: "В реестре",
      checked: true,
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setQ(prev => ({
          ...prev,
          [Q.IN_REGISTER]: e.target.checked
        }));
      }
    },
    {
      type: FILTERS_TYPE.AUTOCOMPLETE,
      name: Q.INDICATOR_METHODOLOGY,
      q: { _key: `${methodologyData?.results[0]?.id} ${modalProps.currentCultivar.id}` },
      label: "Признак",
      handleChange: (payload) => {
        setQ(prev => ({
          ...prev,
          [Q.INDICATOR_METHODOLOGY]: payload?.id
        }));
      },
      fetcher: (q: IArgs) => {
        return api.regulatoryInfo.getIndicatorMethodologyList({
          page: q.page,
          page_size: PAGE_SIZE,
          search: q.search,
          methodology: methodologyData?.results[0]?.id,
        });
      },
      initialSearch: "",
    },
    {
      type: FILTERS_TYPE.AUTOCOMPLETE,
      name: Q.REFERENCE_GROUP_PLAN,
      q: {
        _key: `${methodologyData?.results[0]?.id}${modalProps.currentCultivar.id}${q.indicator_methodology}`,
        ...q ,
        // id: q.indicator_methodology 
      },
      label: "Степень выраженности",
      handleChange: (payload) => {
        setQ(prev => ({
          ...prev,
          [Q.REFERENCE_GROUP_PLAN]: payload?.id
        }));
        return (
          {
            ...payload,
            label: payload?.variant?.severity
          }
        )
      },
      getLabel: (subject: IVariantMeth) => subject.variant.severity || "-",
      disabled: !q.indicator_methodology,
      fetcher: (q: IArgs) => {
        return api.regulatoryInfo.getVariantIndicatorMethodology({
          page: q.page,
          page_size: PAGE_SIZE,
          search: q.search,
          first_ids: q.first_ids,
          id: q.indicator_methodology as number,
        });
      },
      initialSearch: "",
    }
  ], [q, methodologyData])

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add
  );

  const props = {
    listTitle: "Сорта",
    ...modalProps,
    initialValue: modalProps.initialValue,
    key: ["GET_VARIETY", modalProps.currentCultivar.id],
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    title: modalProps.title || "Сорт",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
    filters,
    noWrapFilters: true,
    q
  };

  return (
    <Box>
      {methodologyData ?
        <AddEntities<IVariety>
          modalProps={props}
          handleClose={handleClose}
        /> : null}
    </Box>
  );
};

export default SelectVarieties;
