import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  radioClasses,
  RadioGroup,
  Stack,
  Tooltip,
  typographyClasses,
  useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import Empty from "@/apps/Modals/components/Empty/Empty";
import { useInfiniteScroll } from "@/hooks";
import { Search } from "@/components/form/search";
import { Fetcher } from "swr";
import {
  IPaginatedResult
} from "@/api/interfaces/responses";
import { isUndefined } from "@/utils/helpers";
import { styled } from "@mui/system";
import UncontolledAutoComplete, {
  IProps as IAutocompleteProps
} from "@/components/Autocompletes/UncontolledAutoComplete";
import { FILTERS_TYPE } from "@/const";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${radioClasses.root}`]: {
    padding: 0
  },
  [`& .${typographyClasses.root}`]: {
    fontSize: 14,
    padding: theme.spacing(1)
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.blackAndWhite.stroke}`
}));

interface IAutocompleteFilter extends IAutocompleteProps {
  type: typeof FILTERS_TYPE.AUTOCOMPLETE;
}

interface ICheckboxFilter {
  type: typeof FILTERS_TYPE.CHECKBOX,
  label: string;
  name: string,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  checked?:boolean
}

type TFilterType = ICheckboxFilter | IAutocompleteFilter

export interface IAddEntity<T> {
  handleClose: () => void;
  modalProps: {
    title: string;
    subTitle?: string
    acceptButtonLabel?: string;
    addButtonLabel?: string;
    handleAccept: (payload: T) => void;
    disabled?: boolean;
    initialValue?: T;
    filters?: TFilterType[]
    listTitle?: string;
    handleAddClose?: () => void;
    key?: string | any[];
    q?: Record<string, any>;
    fetcher: Fetcher<IPaginatedResult<T>>;
    getLabel: (payload: T) => string;
    getMeta?: (payload: T) => string;
    handleAdd?: () => void;
    isDisabledBtnAdd?: boolean;
    tooltipText?: string | undefined;
    revalidateOnMount? : boolean;
  };
}

const QUERY_KEY = "GET_ENTITY";

/**
 * Модалка для добавления сущности заявитель/представитель/автор итп
 * */

const AddEntity = <T extends { id: number }> ({
  handleClose,
  modalProps
}: IAddEntity<T>) => {
  const {
    addButtonLabel,
    disabled = false,
    handleAccept,
    initialValue,
    handleAdd,
    fetcher,
    getLabel,
    getMeta,
    key,
    isDisabledBtnAdd = false,
    tooltipText,
    filters = [],
    listTitle = "",
    acceptButtonLabel = "Сохранить",
    q = {},
    revalidateOnMount = undefined,
    subTitle = "",
  } = modalProps;
  const [value, setValue] = useState(initialValue);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const handleSearch = (value: string): void => {
    setSearch(value);
    handleReset();
  };
  const isDisabled = !value || disabled;

  const handleSubmit = () => {
    if (value) {
      handleAccept(value);
    }
  };
  const getKey = (index: number) => {
    return {
      ...q,
      key: key || QUERY_KEY,
      page: index + 1,
      search,
    };
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(JSON.parse(event.target.value));
  };

  const {
    data,
    handleScroll,
    handleReset,
    isEmpty
  } = useInfiniteScroll<T>(
    getKey,
    fetcher,
    {
      revalidateFirstPage: false,
      revalidateOnMount
    }
  );

  const renderFilters = () => Array.isArray(filters) && filters.length ? (
    <Box p={3}>
      <Typography variant="body2"
                  color={theme.palette.blackAndWhite.gray}
                  mb="20px">
        Фильтры
      </Typography>
      <Box flexWrap="wrap" display="flex" gap="20px">
        {
          filters.map(filter => {
            return filter.type === FILTERS_TYPE.CHECKBOX
              ? (
                <FormControlLabel
                  key={filter.name}
                  name={filter.name}
                  control={<Checkbox checked={filter.checked} size="small" disableTouchRipple={true}/>}
                  label={<Typography variant="body2">{filter.label}</Typography>}
                  onChange={filter.handleChange}
                />
              )
              : (
                <UncontolledAutoComplete key={filter.name} {...filter}
                                         fullWidth/>
              );
          })
        }
      </Box>
    </Box>
  ) : null;

  return (
    <Box>
      <Box
        p={3}
        marginRight={6}
        display="flex"
        justifyContent="space-between"
        alignItems= {subTitle ? "flex-start" : "center" }
      >
        <Stack 
          direction="column"
          spacing={1}>
          { subTitle ? 
            <Typography 
              color={theme.palette.blackAndWhite.gray} 
              variant="body1" 
              mb={"-8px"}
              maxWidth={"500px"}
              >
              {modalProps.subTitle}
            </Typography> : null
          }
          <Typography variant="h2" fontSize={24}>
            {modalProps.title}
          </Typography>
        </Stack>
        {addButtonLabel && (
          <Tooltip
            title={!isDisabledBtnAdd ? tooltipText : ""}
            followCursor>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleAdd}
                disabled={isUndefined(handleAdd) || !isDisabledBtnAdd}
              >
                {addButtonLabel}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Divider/>
      <Box>
        {renderFilters()}
        {listTitle && (
          <Box pl={3} pr={3}>
            <Typography variant="body2"
                        color={theme.palette.blackAndWhite.gray}>
              {listTitle}
            </Typography>
          </Box>
        )}
        <Box p={3}>
          <Search onSearch={handleSearch} size="small"
                  fullWidth/>
        </Box>
        <Box
          p={3}
          pt={0}
          sx={{
            height: 300,
            overflowY: "auto"
          }}
          onScroll={handleScroll}
        >
          <Box>
            {isEmpty ? <Empty/> : null}
            <RadioGroup name="values" value={value}
                        onChange={handleRadioChange}>
              <Stack direction="column" spacing="20px">
                {data.map((el) => {
                  return (
                    <StyledBox key={el.id}>
                      <StyledFormControlLabel
                        checked={el.id === value?.id}
                        value={JSON.stringify(el)}
                        control={<Radio color="secondary"/>}
                        label={
                          <Typography>{getLabel(el)}</Typography>}
                      />
                      <Typography
                        sx={{
                          color: theme.palette.blackAndWhite.gray,
                          fontSize: 14
                        }}
                      >
                        {getMeta && getMeta(el)}
                      </Typography>
                    </StyledBox>
                  );
                })}
              </Stack>
            </RadioGroup>
          </Box>
        </Box>
      </Box>
      <Divider/>
      <Box p={3} display="flex"
           justifyContent="space-between">
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={isDisabled}
          onClick={handleSubmit}
        >
          {acceptButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEntity;
