import { IIndicator, IMethList, IVariantMeth, IVarietyIndicatorRes } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    varietyId: number,
    indicatorType: string,
  };
  onSubmit?: () => void;
}

interface ISelectValue {
  id: number;
  label: string;
}

export enum FIELD_NAME {
  VARIETY = "variety",
  INDICATOR_TYPE = "indicator_type",
  METHODOLOGY = "methodology",
  INDICATOR_METHODOLOGY = "indicator_methodology",
  VARIANT_INDICATOR_METHODOLOGY = "variant_indicator_methodology",
}

export interface IFormik {
  [FIELD_NAME.VARIETY]: number,
  [FIELD_NAME.INDICATOR_TYPE]: string,
  [FIELD_NAME.METHODOLOGY]?: IMethList | ISelectValue | null,
  [FIELD_NAME.INDICATOR_METHODOLOGY]?: IIndicator | ISelectValue | null,
  [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]?: IVariantMeth | ISelectValue | null,
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]: yup.mixed().required(),
});

export const INDICATOR_TYPE = {
  morphological: "morphological",
  economically_valuable: "economically_valuable",
}

export const serializedValues = (data: IVarietyIndicatorRes, varietyId: number, indicatorType: string): IFormik => {
  
  return (
    {
      [FIELD_NAME.VARIETY]: varietyId,
      [FIELD_NAME.INDICATOR_TYPE]: indicatorType,
      [FIELD_NAME.METHODOLOGY]: data
        ? {
          id: data.variant_indicator_methodology.indicator_methodology.methodology.id,
          label: data.variant_indicator_methodology.indicator_methodology.methodology.name
        }
        : null,
      [FIELD_NAME.INDICATOR_METHODOLOGY]: data
        ? {
          id: data.variant_indicator_methodology.indicator_methodology.id,
          label: data.variant_indicator_methodology.indicator_methodology.indicator.name
        }
        : null,
      [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]: data
        ? {
          id: data.variant_indicator_methodology.id,
          label: data.variant_indicator_methodology.variant.severity
        }
        : null,
    }
  );
}
