import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import {
  TForm,
  EMPLOYEE_FIELD_NAME,
  SUBDIVISION_TYPES,
  SUBDIVISION_CONST,
  IProps,
} from "../../const";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SWR_KEYS } from "@/const";
import {
  SubdivisionDepartment,
  SubdivisionBranch,
  SubdivisionGSU,
} from "./subdivision";
import Autocomplete from "@/components/Autocomplete";

export const CustomPaper = (props) => {
  return (
    <Paper
      sx={{
        marginBottom: "7px",
      }}
      elevation={1}
      {...props}
    />
  );
};

const EmployeeFormFirstStep: React.FC<IProps> = ({modalProps}) => {
  const { api } = useStores();
  const form = useFormikContext<TForm>();
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [postSearch, setPostSearch] = useState("");
  const swrKeyPost = SWR_KEYS.getPostList();

  /**
   * Метод для изменения подразделений
   * @param event
   */

  const handleChange = (event) => {
    form.setFieldValue(event.target.name, event.target.value);
  };

  /**
   * Список типов подразделений
   */
  const renderItems = SUBDIVISION_TYPES?.map((el) => (
    <MenuItem key={el.value} value={el.value}>
      {el.label}
    </MenuItem>
  ));

  /**
   * Метод для изменения должности
   * @param event
   */
  const handleChangePost = (event,value) => {
    form.setFieldValue(EMPLOYEE_FIELD_NAME.POST, value);
  };

  /**
   * Список типов должности
   */

  const getKeyPost = (index: number) => ({
    _key: swrKeyPost,
    page: index + 1,
    search: postSearch,
  });

  const fetcherPost = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPostList({
      page,
      search,
    });
  };

  const { data, handleScroll } = useInfiniteScroll(
    getKeyPost,
    fetcherPost,
    {
      revalidateFirstPage: false,
    },
  );

  const SUBDIVISION_MODULE : Record<string, React.FC<{ modalProps?: IProps['modalProps'] }>> = {
    [SUBDIVISION_CONST.DEPARTMENT]: SubdivisionDepartment,
    [SUBDIVISION_CONST.BRANCH]: SubdivisionBranch,
    [SUBDIVISION_CONST.GSU]: SubdivisionGSU,
  };

  useEffect(() => {
    const subdivision = form.values.subdivision;
    if (subdivision) {
      const Component = SUBDIVISION_MODULE[subdivision];
  
      if (Component) {
        setContent(<Component modalProps={modalProps} />);
      } else {
        setContent(null);
      }
    }
  }, [form.values.subdivision, modalProps]);

  console.log(form.values)

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Field name={EMPLOYEE_FIELD_NAME.LAST_NAME}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label='Фамилия'
                fullWidth
                size='small'
                required
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[EMPLOYEE_FIELD_NAME.LAST_NAME]}
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={EMPLOYEE_FIELD_NAME.FIRST_NAME}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label='Имя'
                fullWidth
                size='small'
                required
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[EMPLOYEE_FIELD_NAME.FIRST_NAME]}
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={EMPLOYEE_FIELD_NAME.MIDDLE_NAME}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label='Отчество'
                fullWidth
                size='small'
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[EMPLOYEE_FIELD_NAME.MIDDLE_NAME]}
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={EMPLOYEE_FIELD_NAME.DATIVE_NAME}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label='Фамилия в дательном падеже'
                fullWidth
                size='small'
                required
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[EMPLOYEE_FIELD_NAME.DATIVE_NAME]}
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={EMPLOYEE_FIELD_NAME.POST}>
          {({ field, meta }) => (
            <FormControl size='small' variant='outlined' fullWidth>
              <Autocomplete
                {...field}
                PaperComponent={CustomPaper}
                label='Должность'
                onChange={handleChangePost}
                data={data}
                handleScroll={handleScroll}
                search={setPostSearch}
                error={meta.touched && !!meta.error}
              />
            </FormControl>
          )}
        </Field>
        <Field name={EMPLOYEE_FIELD_NAME.SUBDIVISION}>
          {({ field, meta }) => (
            <FormControl size='small' variant='outlined' fullWidth required>
              <InputLabel id='select-label'>Подразделение</InputLabel>
              <Select
                {...field}
                onChange={handleChange}
                label='Подразделение'
                required
                fullWidth
                disabled={meta.isSubmitting}
              >
                {renderItems}
              </Select>
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        {content}
        <Field type='checkbox' name={EMPLOYEE_FIELD_NAME.RIGHT_SIGN}>
          {({ field }) => (
              <FormControlLabel
                {...field}
                checked={form.values.right_sign}
                control={<Checkbox />}
                label='Право подписи'
                sx={{width: 'fit-content'}}
              />
          )}
        </Field>
      </Stack>
    </>
  );
};

export default EmployeeFormFirstStep;


