import React from "react";
import { Container, Stack, Typography } from "@mui/material";

interface IProps {
  emptyText?: string;
}

const Empty: React.FC<IProps> = ({
  emptyText = "Нет результатов"
}) => {
  return (
    <Container>
      <Stack direction='column' spacing={3}>
        <Typography
          variant="h2"
          pt="50px"
          pb="50px"
          align="center"
        >
          {emptyText}
        </Typography>
      </Stack>
    </Container>
  );
};

export default Empty;