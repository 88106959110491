import yup from "@/utils/yup";
import { ERROR_MESSAGES, HP_TYPE } from "@/const";
import { serializeContext } from "./utils";
import { ValueOf } from "@/utils/types";
import { IBranchTrial } from "@/api/interfaces/responses";

interface IValue {
  id: number;
  label: string;
}

export const TRIAL_FIELD_NAME = {
  YEAR_OF_TESTING: "year_of_testing", //Год испытаний
  TYPE_HP: "type_hp", //Подвид испытаний
  REGIONS: "regions", //Регионы допуска
  LIGHT_ZONES: "light_zones", // Световая зона,
  GROUP: "group", // Группа хп
  SURVEYS: "surveys", // Описание-Анкеты
  INSPECTION_ADDRESS: "inspection_address",
  INSPECTIONS_NUMBER: "inspections_number",
  PAYMENT_TYPE: 'payment_type',

  INDICATOR_METHODOLOGY: "indicator_methodology", // Признак методики
  BRANCHES: "branches", // Филиал
  SEVERITY: "severity", // Степень выраженности - Значение признака
  USE_DIRECTION_ID: "use_direction_id" // Направление использования
} as const;

export type THpFormik = {
  [TRIAL_FIELD_NAME.TYPE_HP]: ValueOf<typeof HP_TYPE>
  [TRIAL_FIELD_NAME.INSPECTION_ADDRESS]: IValue | null
  [TRIAL_FIELD_NAME.INSPECTIONS_NUMBER]: number | string
  [TRIAL_FIELD_NAME.YEAR_OF_TESTING]: number | string,
  [TRIAL_FIELD_NAME.BRANCHES]: IBranchTrial[] | null,
  context: ReturnType<typeof serializeContext>;
  surveys: any;
  errors: string
};

export const initialValues = {
  [TRIAL_FIELD_NAME.YEAR_OF_TESTING]: null,
  [TRIAL_FIELD_NAME.TYPE_HP]: HP_TYPE.ACCORDING_REGIONS_RF,
  [TRIAL_FIELD_NAME.REGIONS]: [],
  [TRIAL_FIELD_NAME.LIGHT_ZONES]: [],
  [TRIAL_FIELD_NAME.SURVEYS]: [],
  [TRIAL_FIELD_NAME.USE_DIRECTION_ID]: "",
  [TRIAL_FIELD_NAME.GROUP]: "",
  [TRIAL_FIELD_NAME.PAYMENT_TYPE]: "budget",
  [TRIAL_FIELD_NAME.BRANCHES]: [],

};

export const validationSchema = yup.object().shape({
  [TRIAL_FIELD_NAME.YEAR_OF_TESTING]: yup.string().required(),
  [TRIAL_FIELD_NAME.TYPE_HP]: yup.string().required(),
  [TRIAL_FIELD_NAME.USE_DIRECTION_ID]: yup.string().required(),
  [TRIAL_FIELD_NAME.GROUP]: yup.string().required(),

  [TRIAL_FIELD_NAME.BRANCHES]: yup.mixed()
  .when([TRIAL_FIELD_NAME.TYPE_HP], {
    is: HP_TYPE.ACCORDING_EXPERT_ASSESSMENT,
    then: () => yup.array().min(1, ERROR_MESSAGES.REQUIRED).required(),
    otherwise: schema => schema.nullable(),
  }),
  [TRIAL_FIELD_NAME.INSPECTION_ADDRESS]: yup.mixed().when([TRIAL_FIELD_NAME.TYPE_HP], {
    is: HP_TYPE.ACCORDING_EXPERT_ASSESSMENT,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable()
  }),
  [TRIAL_FIELD_NAME.INSPECTIONS_NUMBER]: yup.number().when([TRIAL_FIELD_NAME.TYPE_HP], {
    is: HP_TYPE.ACCORDING_EXPERT_ASSESSMENT,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable()
  }),
  [TRIAL_FIELD_NAME.REGIONS]: yup
    .array()
    .min(1, ERROR_MESSAGES.REQUIRED)
    .required(),
  [TRIAL_FIELD_NAME.LIGHT_ZONES]: yup
    .array(),
  [TRIAL_FIELD_NAME.SURVEYS]: yup.array().of(
    yup.object().shape({
      [TRIAL_FIELD_NAME.INDICATOR_METHODOLOGY]: yup.string().required(),
      [TRIAL_FIELD_NAME.SEVERITY]: yup.string().required()
    })
  )
});

export const HP_TYPE_VALUES = [
  {
    value: HP_TYPE.ACCORDING_REGIONS_RF,
    label: "По данным государственных испытаний в регионах РФ"
  },
  {
    value: HP_TYPE.ACCORDING_EXPERT_ASSESSMENT,
    label:
      "На участке заявителя"
  },
  {
    value: HP_TYPE.ACCORDING_APPLICANT,
    label: "По данным заявителя"
  }
];