import React from "react";
import { Form, Formik } from "formik";
import {
  defaultValues,
  FIELDS_NAME,
  IFormik,
  validationSchema,
} from "./const";
import { IRegionItem } from "@/api/interfaces/responses";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import {
  RegionAutocomplete
} from "@/components/Autocompletes";

interface IProps {
  handleClose: () => void;
  modalProps: {
    handleAccept: (payload: IRegionItem) => void,
    isPhyto: boolean,
  };
}

const SelectRegion: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    modalProps.handleAccept && modalProps.handleAccept(values.region as IRegionItem);
    handleClose();
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form noValidate>
          <Box>
            <Box p={3}>
              <Typography variant="h2">
                Добавить регион
              </Typography>
            </Box>
            <Divider/>
            <Stack p={3} direction="column" spacing={3}>
              <RegionAutocomplete
                name={FIELDS_NAME.REGION}/>
            </Stack>
            <Divider/>
            <Box
              p={3}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                variant="text"
                color="red"
                onClick={handleClose}
              >
                Отмена
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Создать
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SelectRegion;