import { AxiosError } from "axios";
import dayjs from "dayjs";

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
export const reject = function (
  arr: any[],
  predicate: any,
) {
  const complement = function (f: (arg0: any) => any) {
    return function (x: any) {
      return !f(x);
    };
  };

  return arr.filter(complement(predicate));
};

export const getTagRegex = function () {
  return /<\/?[^>]+>|&nbsp;/g;
};

export const stripTagsAndTrim = function (str: string) {
  return str.replace(getTagRegex(), "").trim();
};

/**
 * Проверяет, является ли входной параметр объектом, который не является массивом и null.
 *
 * @param {unknown} obj - входной параметр
 * @return {boolean} true, если входной параметр является объектом, который не является массивом и не равен null, в противном случае - false
 */
export const isObject = (
  obj: unknown,
): obj is Record<string, any> =>
  typeof obj === "object" &&
  !Array.isArray(obj) &&
  obj !== null;
export const isString = (str: unknown): str is string => {
  if (str != null && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
};

export const isNumber = (n: unknown): n is number => {
  return typeof n === "number" && isFinite(n);
};

export const isEmptyObject = (obj: {
  [key: string]: any;
}) => {
  return Object.keys(obj).length === 0;
};

// Функция для проверки, является ли строка римской цифрой
export const isRomanNumeral = (value) => {
  // Регулярное выражение для проверки строки на соответствие римским цифрам
  const romanNumeralRegex =
    /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
  return romanNumeralRegex.test(value);
};

export const debounce = (
  func: { apply: (arg0: undefined, arg1: any[]) => void },
  timeout = 300,
) => {
  let timer: NodeJS.Timeout | undefined;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const flatten = (arr: any[]) =>
  arr.reduce(
    (a: string | any[], b: any) => a.concat(b),
    [],
  );

export const removeEmptyValues = (obj: object) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val &&
        typeof val === "object" &&
        removeEmptyValues(val)) ||
      ((val === null ||
        val === "" ||
        (isEmpty(val) && val !== 0) ||
        val === undefined) &&
        delete obj[key]),
  );
  return obj;
};

export const pick = (
  obj: { [x: string]: any },
  ...props: any[]
) => {
  return props.reduce((result, prop) => {
    result[prop] = obj[prop];
    return result;
  }, {});
};

export const omit = (obj: any, ...keys: any[]) => {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  const result = { ...obj };
  keysToRemove.forEach(function (prop) {
    delete result[prop];
  });
  return result;
};

export const partition = (
  arr: any[],
  filterFn: (item: any, index?: number) => boolean,
) => {
  return arr.reduce(
    (
      acc: any[][],
      item: any,
      index: number | undefined,
    ) => {
      if (filterFn(item, index)) {
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }
      return acc;
    },
    [[], []],
  );
};

/** проверяет значение на null  */
export const isNull = (value: unknown): value is null => {
  return value === null;
};

/** проверяет значение на undefined  */
export const isUndefined = (
  value: unknown,
): value is undefined => {
  return value === undefined;
};

/** проверяет массив на наличие элементов  */
export const isEmptyArray = (value: unknown[]): boolean => {
  return value.length === 0;
};

/** Принимает имя, фамилию и почту и возвращает инициалы пользователя или первые символы почты  */
export const getInitials = (
  firstName: string,
  lastName: string,
  email?: string | null,
): string => {
  if (isUndefined(email) || isNull(email)) {
    const initials: string = `${firstName[0] ?? ""}${lastName[0] ?? ""
      }`;
    return initials;
  }
  if (!isNull(email) && !isUndefined(email)) {
    const initials: string =
      `${firstName[0] ?? ""}${lastName[0] ?? ""}` !== ""
        ? `${firstName[0] ?? ""}${lastName[0] ?? ""}`
        : `${email.substring(0, 2)}`;
    return initials;
  }
  return "";
};
/** Принимает имя, фамилию и отчество и возвращает полное имя  */
export const getFullName = (payload: {
  first_name?: string;
  last_name?: string;
  second_name?: string;
  middle_name?: string;
}): string => {
  const mn = payload.middle_name
    ? payload.middle_name
    : payload.second_name
      ? payload.second_name
      : "";
  const fullName: string = `${payload?.last_name ?? ""} ${payload?.first_name ?? ""
    } ${mn}`;
  return fullName;
};

/**
 * @function
 * Функция для проверки доступности localStorage
 */
export const isLocalStorageAvailable = () =>
  typeof window !== "undefined" && window.localStorage;

/** Конвертирует ошибку из Аксиос в строку */
export const errorToString = (e: AxiosError): string => {
  if (
    e.config.responseType === "blob"
  ) {
    let errorMessage = "Ошибка загрузки файла"
    const reader = new FileReader();
    reader.readAsText(e?.response?.data);
    reader.onload = (e) => {
      const errorData = JSON.parse(String(e?.target?.result));
      const message = errorData.detail;
      errorMessage = message
    };
    return errorMessage
  }
  if (
    e.response &&
    e.response.data &&
    e.response.data.detail
  ) {
    if (typeof e.response.data.detail === "string") {
      return e.response.data.detail;
    } else if (Array.isArray(e.response.data.detail)) {
      return e.response.data.detail.join(", ");
    } else {
      return Object.values(e.response.data.detail).join(
        ", ",
      );
    }
  } else {
    if (e.response && Array.isArray(e.response.data)) {
      return e.response.data.join(", ");
    }
    if (e.response && typeof e.response.data === "object") {
      return Object.values(e.response.data).join(", ");
    }
    return "Ошибка сервера";
  }
};

/**
 * @param obj - объект для превращения в квери стрингу
 * @returns
 */
export const objectToQueryString = (obj: {
  [x: string]: string | number | boolean;
}) => {
  return Object.keys(obj)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          obj[key],
        )}`,
    )
    .join("&");
};

export const convertStringToDate = (
  str: string,
): string | undefined => {
  const isValidDate = dayjs(str.toLocaleString()).isValid();

  return isValidDate
    ? dayjs(str.toLocaleString()).format("YYYY-MM-DD")
    : undefined;
};

/**
 * Formats date string to "DD.MM.YYYY HH:mm" format.
 * @function
 * @param {string} dateString - The input date string.
 * @returns {string} - Formatted date string.
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(
    2,
    "0",
  );
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(
    2,
    "0",
  );

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

/** Перевод байтов в кб */
export const formatSize = (size: number): number =>
  Math.floor(size / 1024);

export const flattenData = (
  obj: any,
  parentPath: string = "",
): {
  path: string;
  value: string;
}[] => {
  let result: {
    path: string;
    value: string;
  }[] = [];

  for (const key in obj) {
    const currentPath = parentPath
      ? `${parentPath}.${key}`
      : key;

    if (Array.isArray(obj[key])) {
      if (
        obj[key].length === 1 &&
        typeof obj[key][0] === "string"
      ) {
        result.push({
          path: currentPath,
          value: obj[key][0],
        });
      } else {
        result = result.concat(
          flattenData(obj[key], currentPath),
        );
      }
    } else if (typeof obj[key] === "object") {
      result = result.concat(
        flattenData(obj[key], currentPath),
      );
    } else {
      result.push({
        path: currentPath,
        value: obj[key],
      });
    }
  }

  return result;
};

/**
 * @description Обрезает строку до определенного количества символов
 * @param text - строка
 * @param length - длина строки
 */
export const truncateString = (
  text: string,
  length: number,
): string =>
  text.length > length
    ? `${text.substring(0, length)}...`
    : text;

/**
 * @description Добавляет к строке символ если строка не пустая
 * @param str - строка
 * @param symbol - символ, который будет добавлен к строке. Например "руб."
 * @param defaultStr - значение, которое будет установлено, если строка пустая, например "-"
 * @param noConverted - не преобразовывать строку в число
 * @returns
 */
export const stringWithSuffix = (
  str: string | number,
  suffix: string,
  defaultStr: string = "",
  noConverted: boolean = false,
) => {
  if (noConverted) {
    return `${str ? `${str} ${suffix}` : defaultStr}`;
  } else {
    const convertedNumber =
      convertStringNumberToStringWithSpaces(str);
    return `${convertedNumber
      ? `${convertedNumber} ${suffix}`
      : defaultStr
      }`;
  }
};

/**
 * @description Преобразование числа в правильный вид отображения (пробелы между разрядами, разделитель точка)
 * @param numberString - число которое нужно привести к нужному виду
 * @returns
 */
export const convertStringNumberToStringWithSpaces = (
  numberString: string | number,
) => {
  const number = Number(numberString);
  if (isNumber(number)) {
    return number
      .toLocaleString("ru-RU", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .replace(",", "."); // смена разделителя с запятой на точку
  } else {
    return "";
  }
};

/**
 * @description Преобразование числа в правильный вид отображения (пробелы между разрядами)
 * @param payload - число которое нужно привести к нужному виду
 */
export const floatAndSpace = (
  payload: string | number,
) => {
  const number = parseFloat(String(payload));
  if (isNumber(number)) {
    return number
      .toLocaleString("ru-RU");
  } else {
    return "";
  }
};

/**
 * Вычисляет расстояние между двумя маркерами на поверхности Земли.
 *
 * @param {object} firstMarker - Координаты первого маркера.
 * @param {object} lastMarker - Координаты последнего маркера.
 * @return {number} Расстояние между двумя маркерами в километрах.
 */
export const calculateDistance = (
  firstMarker,
  lastMarker,
) => {
  const EARTH_RADIUS_KM = 6371;
  const TO_RADIAN = Math.PI / 180;
  const deltaF =
    (lastMarker.lat - firstMarker.lat) * TO_RADIAN;
  const deltaL =
    (lastMarker.lng - firstMarker.lng) * TO_RADIAN;
  const angle =
    Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
    Math.cos(firstMarker.lat * TO_RADIAN) *
    Math.cos(lastMarker.lat * TO_RADIAN) *
    Math.sin(deltaL / 2) *
    Math.sin(deltaL / 2);
  const distance =
    2 *
    Math.atan2(Math.sqrt(angle), Math.sqrt(1 - angle)) *
    EARTH_RADIUS_KM;

  return distance;
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++)
    color += letters[Math.floor(Math.random() * 16)];
  return color;
};

/**
 * Преобразует строку с пробелом и запятой в число
 * "10 000.30" to 10000.3
 * @param str
 */
export const commaStringToNumber = (str: string) => {
  return parseFloat(str.trim().replace(",", "."));
};

/**
 * Преобразует строку с запятой в число и возвращает результат с двумя знаками после запятой.
 *
 * @param {string} someString - строка с запятой для преобразования
 * @return {number} преобразованное число с двумя знаками после запятой
 */
export const stringToDecimal = (someString: string) => {
  return Number(
    someString.replace(/\s/g, "").replace(",", "."),
  ).toFixed(2);
};

/**
 * Принимает объект и заменяет поля с null на 0
 * @param obj
 * @returns
 */
export const replaceNullWithZero = (
  obj: Record<string, any>,
) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value === null ? 0 : value,
    }),
    {},
  );

export const uniqByKeepList = (data, key): Array<any> => {
  return [
    ...new Map(data.map((x) => [key(x), x])).values(),
  ];
};

/**
 * Метод для выбора ключей из объекта
 * @param obj - объект
 * @param keys - список выбираемых ключей
 */
export const pickFrom = (obj: Record<string, any> | undefined, keys: Array<string>) => {
  if (!isObject(obj)) {
    return null;
  }
  const newObj = keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
  return Object.keys(newObj).length === 0 ? null : newObj;
};

export const translateWeatherCondition = (key: string) => {
  if (!key) return "Неизвестно"

  const options = {
    very_strong_wind: "Очень сильный ветер",
    hurricane_wind: "Ураганный ветер (ураган)",
    squall: "Шквал",
    tornado: "Смерч",
    very_heavy_rain: "Очень сильный дождь (очень сильный дождь со снегом, очень сильный мокрый снег, очень сильный снег с дождем",
    very_heavy_snow: "Очень сильный снег",
    prolonged_heavy_rain: "Продолжительный сильный дождь",
    large_hail: "Крупный град",
    heavy_dust_storm: "Сильная пыльная (песчаная) буря",
    heavy_blizzard: "Сильная метель",
    heavy_fog: "Сильный туман (сильная мгла)",
    heavy_icing: "Сильное гололедно-изморозевое отложение",
    severe_frost: "Сильный мороз",
    frost: "Заморозки",
    abnormally_hot_weather: "Аномально-жаркая погода",
    extreme_fire_danger: "Чрезвычайная пожарная опасность",
    drought: 'Засуха'
  };

  return options[key] || "Неизвестно"
};


export const downloadFile = (file: Blob, name = "file", fileExtension = "docx") => {
  // Создаем URL для скачивания файла

  const fileUrl = URL.createObjectURL(file);
  // Создаем ссылку на элемент <a> для скачивания файла
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = `${name}.${fileExtension}`; // Устанавливаем имя файла для скачивания
  document.body.appendChild(link);

  // Кликаем на ссылку для скачивания файла
  link.click();

  // Удаляем ссылку на элемент <a>
  document.body.removeChild(link);

  // Освобождаем URL
  URL.revokeObjectURL(fileUrl);
}
