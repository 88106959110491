import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import {
  IPlotList
} from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    currentPlot: { id: number, name: string }
    onSubmit: (payload: IPlotList[]) => void
    initialValue?: IPlotList[]
    key: string
  };
}

const SelectPlot: React.FC<IProps> = ({
    handleClose,
    handlePop,
    modalProps
  }) => {
    const { modalStore, api, userStore } = useStores();
    const handleAccept = (value: IPlotList[]) => {
      modalProps.onSubmit && modalProps.onSubmit(value);
      handlePop();
    };
    const fetcher = (args) => {
      const { page, search, first_ids } = args;
      return api.regulatoryInfo.getPlotList({
        page,
        page_size: PAGE_SIZE,
        search,
        first_ids
      });
    };
    const handleSubmit = (payload: IPlotList[]) => {
      modalStore.setModalPropsByKey(modalProps.key, {
        ...modalProps,
        initialValue: payload
      });
    };
  
    const handleAdd = () => {
      modalStore.open(VARIANT_MODAL.PLOT, {
        onSubmit: handleSubmit,
        initialValue: { plot: modalProps.currentPlot},
      });
    };
    const getLabel = (value: IPlotList): string => value.name;
  
    const isAddPlot = userStore.isPermission(
      PERMISSIONS.regulatory_infoPlot,
      PERMISSION_CRUD.add
    );

    const props = {
      initialValue: modalProps.initialValue,
      key: ["GET_PLOT"],
      addButtonLabel: "Добавить ГСУ",
      isDisabledBtnAdd: isAddPlot,
      title: "Выберите ГСУ",
      handleAccept,
      handleAdd,
      getLabel: getLabel,
      fetcher,
    };
  
    return (
      <AddEntities<any>
        modalProps={props}
        handleClose={handleClose}
      />
    );
  };

export default SelectPlot;