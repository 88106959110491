import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { IVariety } from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { EMPTY_TEXT } from "./const";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    currentCultivar: { id: number, name: string }
    onSubmit: (payload: IVariety[]) => void
    initialValue?: IVariety[]
    varietyIndicatorID?: number
    _key: string
  };
}

/** Модалка с выбором сорта */

const SelectReferencies: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { modalStore, api, userStore } = useStores();
  const handleAccept = (value: IVariety[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (args) => {
    const { page, search, first_ids, cultivar } = args;
    return api.regulatoryInfo.getVarietyList({
      page,
      page_size: PAGE_SIZE,
      search,
      cultivar,
      first_ids,
      variety_indicator: modalProps.varietyIndicatorID,
    });
  };
  const handleSubmit = (payload: IVariety[]) => {
    modalStore.setModalPropsByKey(modalProps._key, {
      ...modalProps,
      initialValue: modalProps.initialValue ? [...modalProps.initialValue, ...payload] : payload
    });
  };
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: { cultivar: modalProps.currentCultivar },
      isStatement: true,
      cultivarEditable: false
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add
  );

  const props = {
    initialValue: modalProps.initialValue,
    key: ["GET_VARIETY", modalProps.currentCultivar.id, modalProps.varietyIndicatorID],
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    title: "Выберите эталон",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
    emptyText: EMPTY_TEXT,
    q:{
      cultivar: modalProps.currentCultivar.id,
      first_ids: modalProps?.initialValue ? modalProps.initialValue.map(el => el.id).toString() : undefined
    }
  };

  return (
    <AddEntities<IVariety>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectReferencies;