import React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import theme from "@/theme";
import {
  QUERY_STRING_MICRO_ROUTS_CULTIVAR,
  RIGHT_SIDEBAR_TYPES,
  RIGHT_SIDEBAR_TYPES_LEVELS,
} from "@/apps/RightSidebar/const";
import { KEYS_SIDEBAR_VARIANT_MODULES } from "@/const";
import { useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import { CultureMenu } from "./MenuCulture";
import { MutatorCallback } from "swr/_internal";
import { IOBCultivar } from "@/api/interfaces/responses";
import { stringWithSuffix } from "@/utils/helpers";

interface ICulturesItemProps {
  renderData: IOBCultivar;
  planId: number;
  mutate: MutatorCallback;
  isDetailOpen?: boolean;
  isHidden?: boolean;
}

export const FinanceCulturesItem: React.FC<ICulturesItemProps> = (props) => {
  const { queryStringSidebarCollector } = useStores();
  const { handleOpen } = useRightSidebar();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    planId,
    renderData,
    mutate,
    isDetailOpen = true,
    isHidden=false,
  } = props;
  const tab = searchParams.get("tab");

  const EMPTY_FIELD = "-";

  const handleClick = () => {
    if (isDetailOpen) {
      const { str } = queryStringSidebarCollector.setup({
        tab: RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.OFFBUDGET_SIDEBAR][tab ? tab.toUpperCase() : ""],
        inner_tab: QUERY_STRING_MICRO_ROUTS_CULTIVAR.SEEDS.path,
        keyDetail: RIGHT_SIDEBAR_TYPES.CULTIVAR,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.OFFBUDGET,
        module_id: planId,
        detail_id: renderData.id,
      });
      handleOpen({
        type: RIGHT_SIDEBAR_TYPES.CULTIVAR,
        modalProps: { id: planId, detail_id: renderData.id },
      });
      setSearchParams(str);
    }
  };

  return (
    <Grid
      sx={{
        marginBottom: "10px",
        padding: "18px 16px",
        border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
        borderRadius: "4px",
        width: "inherit",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Grid
        container
        pb={1}
        justifyContent={"space-between"}
      >
        <Typography variant="h4">
          {renderData.cultivar.name}
        </Typography>
        {isHidden ? null : (
          <CultureMenu
            planId={planId}
            data={renderData}
            mutate={mutate}
          />
        )}
      </Grid>
      <Grid
        container
        alignItems={"flex-start"}
        gap={"10px"}
      >
        <Box width={"100px"}>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Цена:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
          >
            {renderData.price || EMPTY_FIELD}
          </Typography>
        </Box>
        <Box width={"170px"}>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Себестоимость:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
          >
            {renderData.total_cost || EMPTY_FIELD}
          </Typography>
        </Box>
        <Box width={"170px"}>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Доход:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
          >
            {renderData.profit || EMPTY_FIELD}
          </Typography>
        </Box>
        <Box width={"170px"}>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Рентабельность:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
            color={theme.palette.blue.main}
          >
            {stringWithSuffix(
              renderData.profitability,
              "%",
              "-",
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
