import { ValueOf } from "@/utils/types";

export interface IThemeModal {
  DEFAULT: "default";
  W_555: "W_555";
  FULL_SCREEN: "FULL_SCREEN";
}

export interface IVariantModal {
  EMPTY: "EMPTY";
  ADD_STATEMENT: "ADD_STATEMENT";
  ADD_ENTITY: "ADD_ENTITY";
  ADD_ENTITIES: "ADD_ENTITIES";
  CHANGE_PASS: "CHANGE_PASS";
  STATEMENT_DETAIL: "STATEMENT_DETAIL";
  CHANGE_AVATAR: "CHANGE_AVATAR";
  APPLICANTS: "APPLICANTS";
  REPRESENTATIVES: "REPRESENTATIVES";
  AUTHORS: "AUTHORS";
  CENSUS_TAKER: "CENSUS_TAKER";
  VARIETY: "VARIETY";
  SUCCESS_CHANGE_PASS: "SUCCESS_CHANGE_PASS";
  OK_OR_NOT_DELETE: "OK_OR_NOT_DELETE";
  ORIGINATORS: "ORIGINATORS";
  VIEWER_SINGLE_PHOTO: "VIEWER_SINGLE_PHOTO";
  ADD_REMARKS: "ADD_REMARKS";
  ADD_SIGN: "ADD_SIGN";
  REWORK: "REWORK_MODAL";
  CREATE_VARIETY: "CREATE_VARIETY";
  SELECT_CULTIVAR: "SELECT_CULTIVAR";
  SELECT_CULTIVARS: "SELECT_CULTIVARS";
  SELECT_TRIAL: "SELECT_TRIAL";
  DUTIES: "DUTIES";
  SELECT_PLOT: 'SELECT_PLOT';
  SELECT_DISEASES: "SELECT_DISEASES";
  SELECT_USER_SIGN: "SELECT_USER_SIGN";
  SIGN_STATEMENT: "SIGN_STATEMENT";
  UNIQUE_VARIETY_NAME: "UNIQUE_VARIETY_NAME";
  SUCCESS_SING_STATEMENT: "SUCCESS_SING_STATEMENT";
  ADD_CULTIVAR_TO_STATEMENT: "ADD_CULTIVAR_TO_STATEMENT";
  COPY_PLAN: "COPY_PLAN";
  ADD_COST: "ADD_COST";
  ADD_DISTRIBUTION: "ADD_DISTRIBUTION";
  SUCCESS_REJECT_STATEMENT: "SUCCESS_REJECT_STATEMENT";
  SUCCESS_REFINEMENT_STATEMENT: "SUCCESS_REFINEMENT_STATEMENT";
  ADD_OFFBUDGET_CULTIVAR: "ADD_OFFBUDGET_CULTIVAR";
  SUCCESS_ACCEPT_STATEMENT: "SUCCESS_ACCEPT_STATEMENT";
  ADD_ADDRESS: "ADD_ADDRESS";
  CREATE_STATEMENT: "CREATE_STATEMENT";
  SELECT_CENSUS_TAKER: "SELECT_CENSUS_TAKER";
  SELECT_VARIETY: "SELECT_VARIETY";
  ADDRESS: "ADDRESS";
  LIGHT_ZONE: "LIGHT_ZONE";
  SUCCESS_APROVED_OFF_B: "SUCCESS_APROVED_OFF_B";
  SUCCESS_REWORK_OFF_B: "SUCCESS_REWORK_OFF_B";
  SUCCESS_REJECTED_OFF_B: "SUCCESS_REJECTED_OFF_B";
  SUCCESS_SUCCESS_OFF_B: "SUCCESS_SUCCESS_OFF_B";
  ADD_FACT: "ADD_FACT";
  ADD_CHEMISTRY: "ADD_CHEMISTRY";
  ADD_WORK: "ADD_WORK";
  ERROR: "ERROR";
  ADD_EFFECTIVITY: "ADD_EFFECTIVITY";
  ADD_OFF_BUDGET_WAGE_FUND: "ADD_OFF_BUDGET_WAGE_FUND";
  ADD_INVESTMENT: "ADD_INVESTMENT";
  EDIT_CULTIVAR_VARIETY_STATEMENT: "EDIT_CULTIVAR_VARIETY_STATEMENT";
  ADD_REMARK: "ADD_REMARK";
  REWORK_OB_MODAL: "REWORK_OB_MODAL";
  SELECT_OB_USERS: "SELECT_OB_USERS";
  CULTIVAR: "CULTIVAR";
  DELETE_TRIAL: "DELETE_TRIAL";
  GO_TO_HANDBOOK: "GO_TO_HANDBOOK";
  REPRODUCTIONS: "REPRODUCTIONS";
  COUNTRY: "COUNTRY";
  DISEASE: "DISEASE";
  POST: "POST";
  ADD_CULTIVAR_TO_FIELD: "ADD_CULTIVAR_TO_FIELD";
  EMPLOYEE: "EMPLOYEE";
  ADD_ORDER_PLOT: "ADD_ORDER_PLOT";
  QUANTITY: "QUANTITY";
  ADD_DOCUMENT: "ADD_DOCUMENT";
  ORGANIZATION: "ORGANIZATION";
  UNITS: "UNITS";
  MATERIAL_TYPE: "MATERIAL_TYPE";
  ADD_REGION: "ADD_REGION";
  SELECT_TRIAL_PLAN: "SELECT_TRIAL_PLAN";
  REMARK: "REMARK";
  ORDER: "ORDER";
  SUBJECT: "SUBJECT";
  SELECT_BRANCH: "SELECT_BRANCH";
  SELECT_BRANCHES: "SELECT_BRANCHES";
  SELECT_PLANNING_METHODOLOGY_GROUPS: "SELECT_PLANNING_METHODOLOGY_GROUPS";
  SELECT_STANDARD: "SELECT_STANDARD";
  ADD_PLAN_PLOTS: "ADD_PLAN_PLOTS";
  SELECT_SIMILAR_VARIETY: "SELECT_SIMILAR_VARIETY";
  SELECT_REFERENCIES: "SELECT_REFERENCIES";
  SELECT_VARIETIES: "SELECT_VARIETIES";
  DOWNLOAD_ORDER: "DOWNLOAD_ORDER";
  COPY_ORDER: "COPY_ORDER";
  SELECT_METHODOLOGY_INDICATOR: "SELECT_METHODOLOGY_INDICATOR";
  SELECT_REGION: "SELECT_REGION";
  ADD_COMMENTARY: "ADD_COMMENTARY";
  SUCCESS_ADD_PLAN: "SUCCESS_ADD_PLAN";
  EDIT_PLANNING: "EDIT_PLANNING";
  ADD_INDICATOR: "ADD_INDICATOR";
  ADD_PARAMETER: "ADD_PARAMETER";
  ADD_IND_VARIANT: "ADD_IND_VARIANT";
  ADD_METHODOLOGY: "ADD_METHODOLOGY";
  ADD_METH_SIGN: "ADD_METH_SIGN";
  ADD_METH_SIGN_VARIANT: "ADD_METH_SIGN_VARIANT";
  ADD_DUTY: "ADD_DUTY";
  SELECT_CHEMISTRY: "SELECT_CHEMISTRY";
  SELECT_CULTIVAR_FIELD_VARIETY: "SELECT_CULTIVAR_FIELD_VARIETY";
  BRANCH: "BRANCH";
  SELECT_BOOKMARK: "SELECT_BOOKMARK";
  DEPARTMENTS: "DEPARTMENTS";
  VARIETIES: 'VARIETIES',
  SELECT_REGIONS: 'SELECT_REGIONS',
  DISTRICTS: 'DISTRICTS',
  VEGETATION_STAGES: 'VEGETATION_STAGES';
  AGRICULTURAL_MACHINERY: 'AGRICULTURAL_MACHINERY';
  SELECT_VEGETATION_CAUSE: 'SELECT_VEGETATION_CAUSE';
  CULTIVAR_USAGE_TYPE: 'CULTIVAR_USAGE_TYPE';
  CULTIVAR_GROUP: 'CULTIVAR_GROUP';
  VEGETATION_CAUSES: 'VEGETATION_CAUSES';
  SELECT_OBSERVATION_INDICATOR: 'SELECT_OBSERVATION_INDICATOR';
  SELECT_OBSERVATION_DISEASE: 'SELECT_OBSERVATION_DISEASE';
  SELECT_OBSERVATION_WEATHER_RESISTANCE: 'SELECT_OBSERVATION_WEATHER_RESISTANCE';
  PLOT:"PLOT";
  CHEMISTRY: 'CHEMISTRY';
  ADD_AL_WORK: "ADD_AL_WORK";
  EXPENSE: 'EXPENSE';
  BUDGETARY_TRIAL_CALC: 'BUDGETARY_TRIAL_CALC';
  SELECT_TRIALS: 'SELECT_TRIALS';
  DOWNLOAD_PLANNING: "DOWNLOAD_PLANNING";
  ADD_TRIALS: "ADD_TRIALS";
  ADD_PRODUCTIVITY: "ADD_PRODUCTIVITY";
  ADD_LAB_RATE: "ADD_LAB_RATE";
  ADD_EXPERT_TRIAL: "ADD_EXPERT_TRIAL";
  ADD_VARIETIES: "ADD_VARIETIES",
  RATE: 'RATE';
  SELECT_MACHINERY: "SELECT_MACHINERY";
  DOWNLOAD_STATEMENT: "DOWNLOAD_STATEMENT";
  DOWNLOAD_STATEMENT_STATUS: "DOWNLOAD_STATEMENT_STATUS";
  DOWNLOAD_CULTIVAR_FIELD: "DOWNLOAD_CULTIVAR_FIELD";
  WEATHER_DATA: 'WEATHER_DATA';
  YEAR_WEATHER_HISTORY: 'YEAR_WEATHER_HISTORY';
  WEATHER_WORK: 'WEATHER_WORK';
  AGREEMENT: 'AGREEMENT';
  DOWNLOAD_SEED_ARRIVAL_JOURNAL: "DOWNLOAD_SEED_ARRIVAL_JOURNAL";
  ADD_PRODUCTIVITY_TESTING_EXPERT_REVIEW: "ADD_PRODUCTIVITY_TESTING_EXPERT_REVIEW";
  SELECT_OBSERVATION_PARAMETER: 'SELECT_OBSERVATION_PARAMETER';
  ADD_VARIETY_INDICATOR: 'ADD_VARIETY_INDICATOR';
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT';
}

export type TThemeModal = ValueOf<IThemeModal>;
export type TVariantModal = ValueOf<IVariantModal>;

export const THEME_MODAL: IThemeModal = {
  DEFAULT: "default",
  W_555: "W_555",
  FULL_SCREEN: "FULL_SCREEN",
};

export const VARIANT_MODAL: IVariantModal = {
  EMPTY: "EMPTY",
  ADD_ENTITY: "ADD_ENTITY",
  ADD_ENTITIES: "ADD_ENTITIES",
  ADD_STATEMENT: "ADD_STATEMENT",
  STATEMENT_DETAIL: "STATEMENT_DETAIL",
  CHANGE_PASS: "CHANGE_PASS",
  CHANGE_AVATAR: "CHANGE_AVATAR",
  APPLICANTS: "APPLICANTS",
  REPRESENTATIVES: "REPRESENTATIVES",
  AUTHORS: "AUTHORS",
  CENSUS_TAKER: "CENSUS_TAKER",
  SUCCESS_CHANGE_PASS: "SUCCESS_CHANGE_PASS",
  VARIETY: "VARIETY",
  OK_OR_NOT_DELETE: "OK_OR_NOT_DELETE",
  ORIGINATORS: "ORIGINATORS",
  VIEWER_SINGLE_PHOTO: "VIEWER_SINGLE_PHOTO",
  CREATE_VARIETY: "CREATE_VARIETY",
  SELECT_CULTIVAR: "SELECT_CULTIVAR",
  SELECT_CULTIVARS: "SELECT_CULTIVARS",
  SELECT_TRIAL: "SELECT_TRIAL",
  SELECT_TRIAL_PLAN: "SELECT_TRIAL_PLAN",
  DUTIES: "DUTIES",
  CULTIVAR: "CULTIVAR",
  SELECT_PLOT: 'SELECT_PLOT',
  SELECT_DISEASES: "SELECT_DISEASES",
  SIGN_STATEMENT: "SIGN_STATEMENT",
  SELECT_USER_SIGN: "SELECT_USER_SIGN",
  ADD_REMARKS: "ADD_REMARKS",
  ADD_SIGN: "ADD_SIGN",
  REWORK: "REWORK_MODAL",
  UNIQUE_VARIETY_NAME: "UNIQUE_VARIETY_NAME",
  ADD_CULTIVAR_TO_STATEMENT: "ADD_CULTIVAR_TO_STATEMENT",
  SUCCESS_SING_STATEMENT: "SUCCESS_SING_STATEMENT",
  COPY_PLAN: "COPY_PLAN",
  ADD_DISTRIBUTION: "ADD_DISTRIBUTION",
  SUCCESS_REJECT_STATEMENT: "SUCCESS_REJECT_STATEMENT",
  SUCCESS_REFINEMENT_STATEMENT:
    "SUCCESS_REFINEMENT_STATEMENT",
  ADD_COST: "ADD_COST",
  ADD_OFFBUDGET_CULTIVAR: "ADD_OFFBUDGET_CULTIVAR",
  SUCCESS_ACCEPT_STATEMENT: "SUCCESS_ACCEPT_STATEMENT",
  ADD_ADDRESS: "ADD_ADDRESS",
  CREATE_STATEMENT: "CREATE_STATEMENT",
  SELECT_CENSUS_TAKER: "SELECT_CENSUS_TAKER",
  SELECT_VARIETY: "SELECT_VARIETY",
  ADDRESS: "ADDRESS",
  LIGHT_ZONE: "LIGHT_ZONE",
  SUCCESS_APROVED_OFF_B: "SUCCESS_APROVED_OFF_B",
  SUCCESS_REWORK_OFF_B: "SUCCESS_REWORK_OFF_B",
  SUCCESS_REJECTED_OFF_B: "SUCCESS_REJECTED_OFF_B",
  SUCCESS_SUCCESS_OFF_B: "SUCCESS_SUCCESS_OFF_B",
  ADD_FACT: "ADD_FACT",
  ADD_CHEMISTRY: "ADD_CHEMISTRY",
  ADD_WORK: "ADD_WORK",
  ERROR: "ERROR",
  ADD_EFFECTIVITY: "ADD_EFFECTIVITY",
  ADD_OFF_BUDGET_WAGE_FUND: "ADD_OFF_BUDGET_WAGE_FUND",
  ADD_INVESTMENT: "ADD_INVESTMENT",
  EDIT_CULTIVAR_VARIETY_STATEMENT:
    "EDIT_CULTIVAR_VARIETY_STATEMENT",
  ADD_REMARK: "ADD_REMARK",
  REWORK_OB_MODAL: "REWORK_OB_MODAL",
  SELECT_OB_USERS: "SELECT_OB_USERS",
  DELETE_TRIAL: "DELETE_TRIAL",
  GO_TO_HANDBOOK: "GO_TO_HANDBOOK",
  COUNTRY: "COUNTRY",
  DISEASE: "DISEASE",
  POST: "POST",
  ADD_CULTIVAR_TO_FIELD: "ADD_CULTIVAR_TO_FIELD",
  EMPLOYEE: "EMPLOYEE",
  ADD_ORDER_PLOT: "ADD_ORDER_PLOT",
  QUANTITY: "QUANTITY",
  UNITS: "UNITS",
  ADD_DOCUMENT: "ADD_DOCUMENT",
  MATERIAL_TYPE: "MATERIAL_TYPE",
  ADD_REGION: "ADD_REGION",
  REPRODUCTIONS: "REPRODUCTIONS",
  REMARK: "REMARK",
  SUBJECT: "SUBJECT",
  ORDER: "ORDER",
  ORGANIZATION: "ORGANIZATION",
  SELECT_BRANCH: "SELECT_BRANCH",
  SELECT_BRANCHES: "SELECT_BRANCHES",
  SELECT_PLANNING_METHODOLOGY_GROUPS:
    "SELECT_PLANNING_METHODOLOGY_GROUPS",
  SELECT_STANDARD: "SELECT_STANDARD",
  ADD_PLAN_PLOTS: "ADD_PLAN_PLOTS",
  SELECT_SIMILAR_VARIETY: "SELECT_SIMILAR_VARIETY",
  SELECT_REFERENCIES: "SELECT_REFERENCIES",
  SELECT_VARIETIES: "SELECT_VARIETIES",
  DOWNLOAD_ORDER: "DOWNLOAD_ORDER",
  COPY_ORDER: "COPY_ORDER",
  SELECT_METHODOLOGY_INDICATOR:
    "SELECT_METHODOLOGY_INDICATOR",
  SELECT_REGION: "SELECT_REGION",
  ADD_COMMENTARY: "ADD_COMMENTARY",
  SUCCESS_ADD_PLAN: "SUCCESS_ADD_PLAN",
  EDIT_PLANNING: "EDIT_PLANNING",
  ADD_INDICATOR: "ADD_INDICATOR",
  ADD_IND_VARIANT: "ADD_IND_VARIANT",
  ADD_METHODOLOGY: "ADD_METHODOLOGY",
  ADD_METH_SIGN: "ADD_METH_SIGN",
  ADD_METH_SIGN_VARIANT: "ADD_METH_SIGN_VARIANT",
  ADD_DUTY: "ADD_DUTY",
  SELECT_CHEMISTRY: "SELECT_CHEMISTRY",
  SELECT_CULTIVAR_FIELD_VARIETY: "SELECT_CULTIVAR_FIELD_VARIETY",
  BRANCH: "BRANCH",
  SELECT_BOOKMARK: "SELECT_BOOKMARK",
  DEPARTMENTS: "DEPARTMENTS",
  VARIETIES: 'VARIETIES',
  SELECT_REGIONS: 'SELECT_REGIONS',
  DISTRICTS: 'DISTRICTS',
  VEGETATION_STAGES: 'VEGETATION_STAGES',
  AGRICULTURAL_MACHINERY: 'AGRICULTURAL_MACHINERY',
  SELECT_VEGETATION_CAUSE: 'SELECT_VEGETATION_CAUSE',
  CULTIVAR_USAGE_TYPE: 'CULTIVAR_USAGE_TYPE',
  CULTIVAR_GROUP: 'CULTIVAR_GROUP',
  VEGETATION_CAUSES: 'VEGETATION_CAUSES',
  SELECT_OBSERVATION_INDICATOR: 'SELECT_OBSERVATION_INDICATOR',
  SELECT_OBSERVATION_DISEASE: 'SELECT_OBSERVATION_DISEASE',
  SELECT_OBSERVATION_WEATHER_RESISTANCE: 'SELECT_OBSERVATION_WEATHER_RESISTANCE',
  PLOT: 'PLOT',
  CHEMISTRY: 'CHEMISTRY',
  BUDGETARY_TRIAL_CALC: 'BUDGETARY_TRIAL_CALC',
  ADD_AL_WORK: "ADD_AL_WORK",
  EXPENSE: 'EXPENSE',
  SELECT_TRIALS: "SELECT_TRIALS",
  DOWNLOAD_PLANNING: "DOWNLOAD_PLANNING",
  ADD_TRIALS: "ADD_TRIALS",
  ADD_PRODUCTIVITY: "ADD_PRODUCTIVITY",
  ADD_LAB_RATE: "ADD_LAB_RATE",
  ADD_EXPERT_TRIAL: "ADD_EXPERT_TRIAL",
  ADD_VARIETIES: "ADD_VARIETIES",
  RATE:'RATE',
  SELECT_MACHINERY: "SELECT_MACHINERY",
  DOWNLOAD_STATEMENT: "DOWNLOAD_STATEMENT",
  DOWNLOAD_STATEMENT_STATUS: "DOWNLOAD_STATEMENT_STATUS",
  DOWNLOAD_CULTIVAR_FIELD: "DOWNLOAD_CULTIVAR_FIELD",
  WEATHER_DATA: 'WEATHER_DATA',
  YEAR_WEATHER_HISTORY: 'YEAR_WEATHER_HISTORY',
  WEATHER_WORK: 'WEATHER_WORK',
  AGREEMENT: 'AGREEMENT',
  DOWNLOAD_SEED_ARRIVAL_JOURNAL: "DOWNLOAD_SEED_ARRIVAL_JOURNAL",
  ADD_PRODUCTIVITY_TESTING_EXPERT_REVIEW: "ADD_PRODUCTIVITY_TESTING_EXPERT_REVIEW",
  SELECT_OBSERVATION_PARAMETER: 'SELECT_OBSERVATION_PARAMETER',
  ADD_PARAMETER: "ADD_PARAMETER",
  ADD_VARIETY_INDICATOR: 'ADD_VARIETY_INDICATOR',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
};
